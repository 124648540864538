// NewProject.tsx

import React, { useState, useEffect } from 'react';
import './style/NewProject.css'; // Import the CSS file
import axiosRequest from '../utils/axiosRequest'; // Import the axiosRequest function

// Move questionText outside the component
const questionText = [
  'What is the Name of your New Project?',
  'Enter a Short Description of Your Project',
  "What is your Project's Allotted Funding?",
  "What are your Project's Requirements?"
];

interface NewProjectProps {
  setSection: (section: string) => void;
  setSelectedProjectId: (projectId: string) => void; // Add this prop
  isDarkMode: boolean;
}

const NewProject: React.FC<NewProjectProps> = ({ setSection, setSelectedProjectId, isDarkMode }) => {
  const [projectName, setProjectName] = useState('');
  const [projectDescription, setProjectDescription] = useState('');
  const [projectFunding, setProjectFunding] = useState('');
  const [projectRequirements, setProjectRequirements] = useState('');
  const [step, setStep] = useState(1);

  const [displayedText, setDisplayedText] = useState<React.ReactNode>('');

  // New states for handling API call
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  // Define the API URL for creating a project
  const projectsURL = process.env.REACT_APP_PROJECTS_API_URL;

  if (!projectsURL) {
    throw new Error('Create Project API URL is not defined');
  }

  // Typewriter effect for the heading text with a blinking cursor
  useEffect(() => {
    setDisplayedText(''); // Reset displayed text when question changes
    let index = 0;
    const typingSpeed = 15; // Adjust typing speed here (milliseconds)
    let timerId: NodeJS.Timeout;

    const type = () => {
      if (index <= questionText[step - 1].length) {
        setDisplayedText(
          <>
            {questionText[step - 1].substring(0, index)}
            <span className="typing-cursor">|</span>
          </>
        );
        index++;
        timerId = setTimeout(type, typingSpeed);
      } else {
        // Typing complete, keep cursor
        setDisplayedText(
          <>
            {questionText[step - 1]}
            <span className="typing-cursor">|</span>
          </>
        );
      }
    };

    type(); // Start typing effect

    // Cleanup function
    return () => {
      clearTimeout(timerId);
    };
  }, [step]);

  // Handle input changes
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    switch (step) {
      case 1:
        setProjectName(e.target.value);
        break;
      case 2:
        setProjectDescription(e.target.value);
        break;
      case 3:
        setProjectFunding(e.target.value);
        break;
      case 4:
        setProjectRequirements(e.target.value);
        break;
      default:
        break;
    }
  };

  // Handle form submission
  const handleSubmit = async (e?: React.FormEvent) => {
    if (e) e.preventDefault();

    // Reset error state
    setError(null);

    if (step < 4) {
      // Proceed to the next step
      setStep(step + 1);
    } else if (step === 4) {
      // All steps are completed, make the API call to create the project
      const newProject = {
        name: projectName,
        description: projectDescription,
        funding: projectFunding,
        requirements: projectRequirements,
        is_default: false, // Assuming default is false
      };

      setLoading(true);

      try {
        const response = await axiosRequest(`${projectsURL}create/`, 'post', newProject)
        console.log('New Project Created:', response.data);

        // Set the selected project ID
        setSelectedProjectId(response.data.id); // Assuming the backend returns the 'id' field

        // Reset the form
        setProjectName('');
        setProjectDescription('');
        setProjectFunding('');
        setProjectRequirements('');
        setStep(1);

        // Navigate to the ProjectOverview section
        setSection('ProjectDashboard');
      } catch (error: any) {
        console.error('Error creating project:', error);
        setError('An error occurred while creating the project. Please try again.');
      } finally {
        setLoading(false);
      }
    }
  };

  // Handle key down in textarea to submit on Enter key
  const handleTextareaKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSubmit();
    }
  };

  return (
    <div className={`new-project-container ${isDarkMode ? 'dark' : 'light'}`}>
      {/* Wrap the heading in a container */}
      <div className="question-heading-container">
        <h1
          tabIndex={0}
          role="heading"
          aria-level={1}
          className={`question-heading ${isDarkMode ? 'dark' : 'light'}`}
        >
          {displayedText}
        </h1>
      </div>

      <form className="new-project-form" onSubmit={handleSubmit}>
        {step === 1 && (
          <div className="input-container">
            <label htmlFor="project-name" className="visually-hidden">
              Project Name
            </label>
            <input
              id="project-name"
              type="text"
              value={projectName}
              onChange={handleInputChange}
              placeholder="Enter project name"
              required
              className={`${isDarkMode ? 'dark' : 'light'}`}
            />
            <button
              type="submit"
              className={`submit-button ${isDarkMode ? 'dark' : 'light'}`}
              aria-label="Next"
            >
              ➤
            </button>
          </div>
        )}

        {step === 2 && (
          <div className="input-container textarea-container">
            <label htmlFor="project-description" className="visually-hidden">
              Project Description
            </label>
            <textarea
              id="project-description"
              value={projectDescription}
              onChange={handleInputChange}
              onKeyDown={handleTextareaKeyDown}
              placeholder="Enter project description"
              required
              className={`${isDarkMode ? 'dark' : 'light'}`}
            />
            <button
              type="submit"
              className={`submit-button ${isDarkMode ? 'dark' : 'light'}`}
              aria-label="Next"
            >
              ➤
            </button>
          </div>
        )}

        {step === 3 && (
          <div className="input-container">
            <label htmlFor="project-funding" className="visually-hidden">
              Project Funding
            </label>
            <input
              id="project-funding"
              type="number"
              value={projectFunding}
              onChange={handleInputChange}
              placeholder="Enter funding number"
              required
              className={`${isDarkMode ? 'dark' : 'light'}`}
            />
            <button
              type="submit"
              className={`submit-button ${isDarkMode ? 'dark' : 'light'}`}
              aria-label="Next"
            >
              ➤
            </button>
          </div>
        )}

        {step === 4 && (
          <div className="input-container textarea-container">
            <label htmlFor="project-requirements" className="visually-hidden">
              Project Requirements
            </label>
            <textarea
              id="project-requirements"
              value={projectRequirements}
              onChange={handleInputChange}
              onKeyDown={handleTextareaKeyDown}
              placeholder="Enter project requirements"
              required
              className={`${isDarkMode ? 'dark' : 'light'}`}
            />
            <button
              type="submit"
              className={`submit-button ${isDarkMode ? 'dark' : 'light'}`}
              aria-label="Submit"
            >
              ➤
            </button>
          </div>
        )}

        {/* Display loading indicator or error message */}
        {loading && (
          <div className="loading-indicator">
            <p className={`${isDarkMode ? 'dark' : 'light'}`}>Creating project...</p>
          </div>
        )}

        {error && (
          <div className="error-message">
            <p className={`${isDarkMode ? 'dark' : 'light'}`}>{error}</p>
          </div>
        )}
      </form>
    </div>
  );
};

export default NewProject;
