// StakeholderIdentification.tsx

import React, { useState, useEffect, useRef, useContext } from 'react';
import axiosRequest from '../utils/axiosRequest';
import './style/StakeholderIdentification.css';
import Loading from './loading/Loading';
import { Helmet } from 'react-helmet';
import { Document, StakeholderData } from '../types';
import { StakeholderIdentificationContext, useDefaultProjectContext } from '../ContextStore';

interface StakeholderIdentificationProps {
  isDarkMode: boolean;
}

const StakeholderIdentification: React.FC<StakeholderIdentificationProps> = ({ isDarkMode }) => {
  const [query, setQuery] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [errorKey, setErrorKey] = useState<number>(0);
  const [retryAttempt, setRetryAttempt] = useState<boolean>(false);

  // Use the context for response state
  const stakeholderContext = useContext(StakeholderIdentificationContext);
  if (!stakeholderContext) {
    throw new Error('StakeholderIdentificationContext not found');
  }
  const { stakeholderResponse, setStakeholderResponse } = stakeholderContext;

  const { defaultProjectId } = useDefaultProjectContext();

  // Library dropdown state
  const [documents, setDocuments] = useState<Document[]>([]);
  const [selectedDocumentId, setSelectedDocumentId] = useState<string | null>(null);
  const [selectedDocumentTitle, setSelectedDocumentTitle] = useState<string>('Library');
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

  const dropdownRef = useRef<HTMLDivElement>(null);
  const errorRef = useRef<HTMLDivElement>(null);
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const libraryDropdownMenuRef = useRef<HTMLDivElement>(null);
  const libraryDropdownButtonRef = useRef<HTMLButtonElement>(null);

  const stakeURL = process.env.REACT_APP_STAKEHOLDER_IDENTIFICATION_API_URL;
  const documentsURL = process.env.REACT_APP_DOCUMENTS_API_URL;
  if (!stakeURL || !documentsURL) {
    throw new Error('API URLs are not defined');
  }

  // Fetch documents for the library dropdown
  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const response = await axiosRequest(documentsURL, 'get');
        const sortedDocuments = response.data.sort((a: Document, b: Document) => {
          return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
        });
        setDocuments(sortedDocuments);
      } catch (err: any) {
        setError('Error fetching documents');
      }
    };
    fetchDocuments();
  }, [documentsURL]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setRetryAttempt(false);

    // Clear previous response
    setStakeholderResponse([]);

    if (!query.trim() && !selectedDocumentId) {
      setError('Please input a query or select a document.');
      setLoading(false);
      return;
    }

    // Ensure defaultProjectId is available
    if (!defaultProjectId) {
      setError('No default project selected. Please select a project.');
      setLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append('query', query);
    if (selectedDocumentId) {
      formData.append('documentId', selectedDocumentId);
    }
    formData.append('project_id', defaultProjectId);

    try {
      await initiateStreaming(formData);
    } catch (error: any) {
      setLoading(false);
      setErrorKey((prevKey) => prevKey + 1);

      if (error.response && error.response.status === 401) {
        setError('Unauthorized. Please log in with a valid email and password.');
      } else if (error instanceof Error) {
        console.error('Error initiating request:', error);
        setError(`An error occurred while initiating the request: ${error.message}`);
      } else {
        console.error('Unexpected error:', error);
        setError('An unexpected error occurred');
      }
    }
  };

  const initiateStreaming = async (formData: FormData, retry: boolean = false) => {
    try {
      // Make the POST request to get temp_token (JWT) and possibly updated query
      const postResponse = await axiosRequest(stakeURL, 'post', formData);
      const { temp_token, query: updatedQuery } = postResponse.data;

      // Use the updated query from the response if provided
      const finalQuery = updatedQuery || query;

      // Construct the EventSource URL with temp_token (JWT)
      const eventSourceUrl = `${stakeURL}?query=${encodeURIComponent(
        finalQuery
      )}&temp_token=${encodeURIComponent(temp_token)}`;

      const eventSource = new EventSource(eventSourceUrl);

      eventSource.onopen = () => {
        console.log('Connection to server opened.');
      };

      eventSource.onmessage = (event) => {
        const newMessage = JSON.parse(event.data);
        console.log('Received message:', newMessage);

                // if (newMessage.summary) {
        //   handleSummaryResponse(newMessage.summary);
        //   eventSource.close();
        //   setLoading(false);
        // } else {
        //   handleInitialBatchResponse(newMessage as StakeholderData[]);
        // }

        if (newMessage.summary) {
          eventSource.close();
          setLoading(false);
        } else {
          handleInitialBatchResponse(newMessage as StakeholderData[]);
        }
      };

      eventSource.onerror = async (event) => {
        console.error('EventSource error:', event);
        eventSource.close();
        if (!retryAttempt && !retry) {
          setRetryAttempt(true);
          console.warn('Retrying EventSource connection...');
          await initiateStreaming(formData, true);
        } else {
          setLoading(false);
          setError('An error occurred while streaming data.');
          setRetryAttempt(false);
        }
      };
    } catch (error: any) {
      if (!retryAttempt && !retry) {
        setRetryAttempt(true);
        console.warn('Retrying request after error...');
        await initiateStreaming(formData, true);
      } else {
        throw error;
      }
    }
  };

  const handleInitialBatchResponse = (message: StakeholderData[]) => {
    // Ensure message is an array
    const newMessages = Array.isArray(message) ? message : [message];

    setStakeholderResponse((prevResponse: StakeholderData[]) => [...prevResponse, ...newMessages]);
  };

  const handleSummaryResponse = (summary: string) => {
    const formattedSummary: StakeholderData[] = summary.split('\n\n').map((item) => {
      const lines = item.split('\n').map((line) => line.trim());
      const details: StakeholderData = { organization: '' };
      const orgNameMatch = lines[0].match(/^\d+\.\s\*\*(.*?)\*\*/);
      if (orgNameMatch) {
        details.organization = orgNameMatch[1].trim();
      }
      lines.slice(1).forEach((line) => {
        const [key, value] = line.split(':').map((part) => part.replace('**', '').trim());
        if (key && value) {
          const normalizedKey = key.replace(/[^a-zA-Z]/g, '').toLowerCase();
          details[normalizedKey as keyof StakeholderData] = value;
        }
      });
      return details;
    });
    setStakeholderResponse(formattedSummary);
    setLoading(false);
  };

  const formatResponse = (data: StakeholderData, index: number) => {
    return (
      <div id={`result-content-${index}`} className="stakeholder-identification-result-content">
        <div>
          <strong>{data.organization}</strong>
        </div>
        <div>- <strong>Name:</strong> {data.name || 'No data'}</div>
        <div>- <strong>Title:</strong> {data.title || 'No data'}</div>
        <div>- <strong>Email:</strong> {data.email || 'No data'}</div>
        <div>- <strong>Source:</strong> {data.source || 'No data'}</div>
      </div>
    );
  };

  // Focus the error message when it appears
  useEffect(() => {
    if (error && errorRef.current) {
      errorRef.current.focus();
    }
  }, [error]);

  // Close the dropdown menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownOpen &&
        libraryDropdownMenuRef.current &&
        !libraryDropdownMenuRef.current.contains(event.target as Node) &&
        !libraryDropdownButtonRef.current?.contains(event.target as Node)
      ) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownOpen]);

  // Keyboard navigation for the dropdown menu
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (dropdownOpen && libraryDropdownMenuRef.current) {
        const items = libraryDropdownMenuRef.current.querySelectorAll('div[role="menuitem"]');
        const activeIndex = Array.from(items).findIndex((item) => item === document.activeElement);

        switch (event.key) {
          case 'ArrowDown':
            event.preventDefault();
            const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
            (items[nextIndex] as HTMLElement).focus();
            break;
          case 'ArrowUp':
            event.preventDefault();
            const prevIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
            (items[prevIndex] as HTMLElement).focus();
            break;
          case 'Enter':
            event.preventDefault();
            if (activeIndex !== -1) {
              (items[activeIndex] as HTMLElement).click();
            }
            break;
          case 'Tab':
            if (event.shiftKey) {
              // Shift + Tab
              if (activeIndex === 0) {
                event.preventDefault();
                setDropdownOpen(false);
                libraryDropdownButtonRef.current?.focus();
              }
            } else {
              // Tab
              if (activeIndex === items.length - 1) {
                event.preventDefault();
                setDropdownOpen(false);
                submitButtonRef.current?.focus();
              }
            }
            break;
          case 'Escape':
            setDropdownOpen(false);
            libraryDropdownButtonRef.current?.focus();
            break;
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [dropdownOpen]);

  // Set focus to the first menu item when the library dropdown opens
  useEffect(() => {
    if (dropdownOpen && libraryDropdownMenuRef.current) {
      const firstItem = libraryDropdownMenuRef.current.querySelector('div[role="menuitem"]');
      (firstItem as HTMLElement)?.focus();
    }
  }, [dropdownOpen]);

  // Handle library dropdown open/close and selection
  const handleLibraryDropdownClick = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleLibrarySelection = (
    event: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>,
    document: Document | null
  ) => {
    event.preventDefault();
    if ('stopPropagation' in event) event.stopPropagation();

    if (document) {
      setSelectedDocumentId(document.id);
      setSelectedDocumentTitle(document.documentTitle);
    } else {
      setSelectedDocumentId(null);
      setSelectedDocumentTitle('Library');
    }
    setDropdownOpen(false);

    // Delay focusing on the submit button to prevent Enter key press from triggering it
    setTimeout(() => {
      submitButtonRef.current?.focus();
    }, 0);
  };

  return (
    <>
      <Helmet>
        <title>Stakeholder Identification</title>
      </Helmet>
      <nav aria-label="Primary Navigation" className="primary-nav"></nav>
      <main role="main" tabIndex={-1} className="main-content">
        <div className={`stakeholder-identification-container ${isDarkMode ? 'dark' : 'light'}`}>
          <h1 tabIndex={0} role="heading" aria-level={1} className={isDarkMode ? 'dark' : 'light'}>
            Stakeholder Identification
          </h1>
          <p tabIndex={0} role="note" className={isDarkMode ? 'dark' : 'light'}>
            Identifies operational end users or program offices for a user inputted technology or selected document.
          </p>
          <form className="stakeholder-identification-form" onSubmit={handleSubmit}>
            <div className="stakeholder-identification-input-container" ref={dropdownRef}>
              <label htmlFor="stakeholder-identification-query" className="visually-hidden">
                Search Query
              </label>
              <input
                id="stakeholder-identification-query"
                type="text"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                placeholder="Ex: Predictive Maintenance for Tactical Aircraft"
                autoComplete="off"
                aria-describedby="error-message"
                className={`${isDarkMode ? 'dark' : 'light'}`}
              />
              {/* Library Dropdown Button */}
              <button
                type="button"
                className={`stakeholder-identification-dropdown-button ${isDarkMode ? 'dark' : 'light'}`}
                onClick={handleLibraryDropdownClick}
                aria-expanded={dropdownOpen}
                aria-controls="stakeholder-identification-library-dropdown-menu"
                ref={libraryDropdownButtonRef}
              >
                &#128194; {selectedDocumentTitle}
                <span className="stakeholder-identification-tooltip-text">Select a document from your library</span>
              </button>

              <button
                type="submit"
                className={`stakeholder-identification-submit-button ${isDarkMode ? 'dark' : 'light'}`}
                aria-label="Submit Query"
                ref={submitButtonRef}
              >
                &#x27A4;
              </button>

              {/* Library Dropdown Menu - Always Rendered */}
              <div
                className={`stakeholder-identification-library-dropdown-menu ${isDarkMode ? 'dark' : 'light'} ${dropdownOpen ? 'open' : 'closed'}`}
                id="stakeholder-identification-library-dropdown-menu"
                role="menu"
                ref={libraryDropdownMenuRef}
                aria-hidden={!dropdownOpen}
              >
                <div
                  role="menuitem"
                  tabIndex={0}
                  onClick={(event) => handleLibrarySelection(event, null)}
                  className={`stakeholder-identification-dropdown-item ${isDarkMode ? 'dark' : 'light'}`}
                >
                  -- No Document --
                </div>
                {documents.map((document) => (
                  <div
                    key={document.id}
                    role="menuitem"
                    tabIndex={0}
                    onClick={(event) => handleLibrarySelection(event, document)}
                    className={`stakeholder-identification-dropdown-item ${isDarkMode ? 'dark' : 'light'}`}
                  >
                    {document.documentTitle}
                  </div>
                ))}
              </div>
            </div>
            <div
              id="error-message"
              key={errorKey}
              className={`stakeholder-identification-error ${error ? 'visible' : 'hidden'} ${
                isDarkMode ? 'dark' : 'light'
              }`}
              aria-live="assertive"
              role="alert"
              ref={errorRef}
            >
              {error}
            </div>
          </form>
          {loading && <Loading />}
          {stakeholderResponse.length > 0 && (
            <div className={`stakeholder-identification-response ${isDarkMode ? 'dark' : 'light'}`}>
              <h2 tabIndex={0} role="heading" aria-level={2} className={isDarkMode ? 'dark' : 'light'}>
                Results:
              </h2>
              <div className="stakeholder-identification-results-container">
                {stakeholderResponse.map((item, index) => (
                  <div
                    key={index}
                    className={`stakeholder-identification-result-card ${isDarkMode ? 'dark' : 'light'}`}
                    role="region"
                    aria-labelledby={`result-${index}`}
                    tabIndex={0}
                    aria-describedby={`result-content-${index}`}
                  >
                    <div className="visually-hidden" id={`result-${index}`}>
                      Result {index + 1}
                    </div>
                    {formatResponse(item, index)}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </main>
    </>
  );
};

export default StakeholderIdentification;
