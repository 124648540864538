// ProjectDashboard.tsx

import React, { useEffect, useState, useRef } from 'react';
import axiosRequest from '../utils/axiosRequest';
import './style/ProjectDashboard.css';
import { ProjectData } from '../types';

interface ProjectDashboardProps {
  isDarkMode: boolean;
  projectId: string | null; // Allow null in case no project is selected
}

const ProjectDashboard: React.FC<ProjectDashboardProps> = ({ isDarkMode, projectId }) => {
  const [projectData, setProjectData] = useState<ProjectData | null>(null);
  const [techScoutingResults, setTechScoutingResults] = useState<any[]>([]);
  const [stakeholderResults, setStakeholderResults] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const eventSourceRef = useRef<EventSource | null>(null);
  const hasFetchedRef = useRef<boolean>(false); // Prevent duplicate fetches
  const stageRef = useRef<'tech' | 'stakeholders'>('tech');

  const projectsURL = process.env.REACT_APP_PROJECTS_API_URL;
  if (!projectsURL) {
    throw new Error('API URLs are not defined');
  }

  useEffect(() => {
    console.log('ProjectDashboard useEffect triggered with projectId:', projectId);

    if (!projectId) {
      setError('No project selected.');
      return;
    }

    if (hasFetchedRef.current) {
      console.log('Fetch already initiated. Skipping duplicate fetch.');
      return; // Prevent duplicate fetches
    }
    hasFetchedRef.current = true; // Set the flag

    const fetchProjectDashboard = async () => {
      setLoading(true);
      setError(null);
      try {
        // Make a POST request to get the temp_token and project data
        const response = await axiosRequest(`${projectsURL}${projectId}/dashboard/`, 'post');
        const { temp_token, project_data } = response.data;
        console.log('Received temp_token:', temp_token);
        console.log('Received project_data:', project_data);
        setProjectData(project_data);

        // Initiate streaming using EventSource
        const eventSourceUrl = `${projectsURL}${projectId}/dashboard/?temp_token=${encodeURIComponent(temp_token)}`;
        console.log('Initializing EventSource with URL:', eventSourceUrl);
        const eventSource = new EventSource(eventSourceUrl);
        eventSourceRef.current = eventSource;

        eventSource.onmessage = (event) => {
          try {
            const data = JSON.parse(event.data);
            console.log('Received data:', data);

            if (data.results && Array.isArray(data.results)) {
              if (stageRef.current === 'tech') {
                setTechScoutingResults(prev => {
                  const combined = [...prev, ...data.results];
                  console.log('Tech Scouting Combined:', combined);
                  if (combined.length >= 4) {
                    stageRef.current = 'stakeholders';
                  }
                  return combined.slice(0, 4);
                });
              } else if (stageRef.current === 'stakeholders') {
                setStakeholderResults(prev => {
                  const combined = [...prev, ...data.results];
                  console.log('Stakeholder Combined:', combined);
                  return combined.slice(0, 4);
                });
              }
            }
          } catch (parseError) {
            console.error('Error parsing SSE data:', parseError);
          }
        };

        eventSource.addEventListener('end', () => {
          console.log('Streaming ended (via event).');
          eventSource.close();
          eventSourceRef.current = null;
          setLoading(false);
        });

        eventSource.onerror = (evt) => {
          console.error('EventSource error:', evt);
          setError('An error occurred while streaming data.');
          eventSource.close();
          eventSourceRef.current = null;
          setLoading(false);
        };
      } catch (err: any) {
        console.error('Error fetching project dashboard:', err);
        if (err.response && err.response.data && err.response.data.error) {
          setError(err.response.data.error);
        } else {
          setError('Failed to fetch project dashboard.');
        }
        setLoading(false);
      }
    };

    fetchProjectDashboard();

    // Cleanup EventSource when component unmounts
    return () => {
      if (eventSourceRef.current) {
        eventSourceRef.current.close();
        eventSourceRef.current = null;
      }
    };
  }, [projectId, projectsURL]);

  if (error) {
    return (
      <div className={`project-dashboard-container ${isDarkMode ? 'dark' : 'light'}`}>
        <p className={`error-message ${isDarkMode ? 'dark' : 'light'}`}>{error}</p>
      </div>
    );
  }

  if (!projectData) {
    return (
      <div className={`project-dashboard-container ${isDarkMode ? 'dark' : 'light'}`}>
        <p className={`project-dashboard-text ${isDarkMode ? 'dark' : 'light'}`}>Loading project data...</p>
      </div>
    );
  }

  return (
    <div className={`project-dashboard-container ${isDarkMode ? 'dark' : 'light'}`}>
      {/* Header with Project Name */}
      <h1 className={`project-dashboard-heading ${isDarkMode ? 'dark' : 'light'}`}>
        {projectData.name}
      </h1>

      {/* Project Funding directly below Project Name */}
      {projectData.funding && (
        <p className={`project-dashboard-text project-funding ${isDarkMode ? 'dark' : 'light'}`}>
          <strong>Funding:</strong> ${projectData.funding}
        </p>
      )}

      {/* Enhanced Timeline with Icons and Animations */}
      <div className="project-timeline">
        <ul className="timeline">
          <li className="timeline-item" style={{ '--i': 1 } as React.CSSProperties}>
            <span className="timeline-icon" aria-label="Project Initiated">
              <i className="fas fa-rocket"></i>
            </span>
            <span className="timeline-content">Project Initiated</span>
          </li>
          <li className="timeline-item" style={{ '--i': 2 } as React.CSSProperties}>
            <span className="timeline-icon" aria-label="Tech Scouting">
              <i className="fas fa-search"></i>
            </span>
            <span className="timeline-content">Tech Scouting</span>
          </li>
          <li className="timeline-item" style={{ '--i': 3 } as React.CSSProperties}>
            <span className="timeline-icon" aria-label="Stakeholder Identification">
              <i className="fas fa-users"></i>
            </span>
            <span className="timeline-content">Stakeholder Identification</span>
          </li>
          <li className="timeline-item" style={{ '--i': 4 } as React.CSSProperties}>
            <span className="timeline-icon" aria-label="Project Execution">
              <i className="fas fa-clipboard-check"></i>
            </span>
            <span className="timeline-content">Project Execution</span>
          </li>
        </ul>
      </div>

      {/* Project Details in two paragraphs side-by-side */}
      <div className="project-details-row">
        <div className="project-detail-column">
          <p className={`project-dashboard-text ${isDarkMode ? 'dark' : 'light'}`}>
            <strong>Description:</strong> {projectData.description}
          </p>
        </div>
        <div className="project-detail-column">
          <p className={`project-dashboard-text ${isDarkMode ? 'dark' : 'light'}`}>
            <strong>Requirements:</strong> {projectData.requirements}
          </p>
        </div>
      </div>

      {/* Project Info Row with Enhanced Card Styles */}
      <div className="project-info-row">
        {/* Tech Scouting Card */}
        <div className={`project-info-col ${isDarkMode ? 'dark-card' : 'light-card'} animate__animated animate__fadeIn`}>
          <h2 className={`project-dashboard-subheading ${isDarkMode ? 'dark' : 'light'}`}>Tech Scouting</h2>
          {loading && stageRef.current === 'tech' && (
            <p className={`project-dashboard-text ${isDarkMode ? 'dark' : 'light'}`}>
              Loading tech scouting results...
            </p>
          )}
          <ul className={`results-list ${isDarkMode ? 'dark' : 'light'} tech-scouting-list`}>
            {techScoutingResults.map((res, index) => (
              <li key={index} className={`result-item`}>
                {res.company || 'Unknown Company'}
              </li>
            ))}
          </ul>
        </div>

        {/* Stakeholders Card */}
        <div className={`project-info-col ${isDarkMode ? 'dark-card' : 'light-card'} animate__animated animate__fadeIn`}>
          <h2 className={`project-dashboard-subheading ${isDarkMode ? 'dark' : 'light'}`}>Stakeholders</h2>
          {loading && stageRef.current === 'stakeholders' && (
            <p className={`project-dashboard-text ${isDarkMode ? 'dark' : 'light'}`}>
              Loading stakeholder results...
            </p>
          )}
          <ul className={`results-list ${isDarkMode ? 'dark' : 'light'} stakeholders-list`}>
            {stakeholderResults.map((res, index) => (
              <li key={index} className={`result-item`}>
                {res.organization || 'Unknown Organization'}
              </li>
            ))}
          </ul>
        </div>
      </div>

      {/* Additional Project Metadata */}
      <div className={`project-metadata ${isDarkMode ? 'dark' : 'light'} animate__animated animate__fadeIn`}>
        <p className={`project-dashboard-text`}>
          <strong>Created At:</strong> {new Date(projectData.created_at).toLocaleString()}
        </p>
        <p className={`project-dashboard-text`}>
          <strong>Last Updated:</strong> {new Date(projectData.updated_at).toLocaleString()}
        </p>
        <p className={`project-dashboard-text`}>
          <strong>Default Project:</strong> {projectData.is_default ? 'Yes' : 'No'}
        </p>
      </div>
    </div>
  );
};

export default ProjectDashboard;
