// TechScouting.tsx

import React, { useState, useRef, useEffect, useContext } from 'react';
import axiosRequest from '../utils/axiosRequest';
import './style/TechScouting.css';
import Loading from './loading/Loading';
import { Helmet } from 'react-helmet';
import { Document } from '../types';
import { TechScoutingContext, useDefaultProjectContext } from '../ContextStore';

interface TechScoutingProps {
  isDarkMode: boolean;
  setSection: React.Dispatch<React.SetStateAction<string>>;
}

const TechScouting: React.FC<TechScoutingProps> = ({ isDarkMode, setSection }) => {
  // State declarations
  const [query, setQuery] = useState<string>('');
  const [threshold, setThreshold] = useState<number>(0.82); // Default threshold value
  const [loading, setLoading] = useState<boolean>(false);
  const [useSBIR, setUseSBIR] = useState<boolean>(false); // New slider state

  const [precisionDropdownOpen, setPrecisionDropdownOpen] = useState<boolean>(false);
  const [selectedPrecision, setSelectedPrecision] = useState<string>('Precision');

  const [documents, setDocuments] = useState<Document[]>([]);
  const [selectedDocumentId, setSelectedDocumentId] = useState<string | null>(null);
  const [selectedDocumentTitle, setSelectedDocumentTitle] = useState<string>('Library');
  const [libraryDropdownOpen, setLibraryDropdownOpen] = useState<boolean>(false);

  const [error, setError] = useState<string | null>(null);
  const [errorKey, setErrorKey] = useState<number>(0);
  const [s3Url, setS3Url] = useState<string | null>(null);
  const [retryAttempt, setRetryAttempt] = useState<boolean>(false);

  // Refs for accessibility and focus management
  const dropdownRef = useRef<HTMLDivElement>(null);
  const libraryDropdownButtonRef = useRef<HTMLButtonElement>(null);
  const precisionDropdownButtonRef = useRef<HTMLButtonElement>(null);
  const precisionMenuRef = useRef<HTMLDivElement>(null);
  const libraryDropdownMenuRef = useRef<HTMLDivElement>(null);
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const errorRef = useRef<HTMLDivElement>(null);
  const eventSourceRef = useRef<EventSource | null>(null); // Ref to store the EventSource

  // API URLs from environment variables
  const techURL = process.env.REACT_APP_TECH_SCOUTING_API_URL;
  const documentsURL = process.env.REACT_APP_DOCUMENTS_API_URL;

  if (!techURL || !documentsURL) {
    throw new Error('API URLs are not defined');
  }

  // Use the context for response state
  const techScoutingContext = useContext(TechScoutingContext);
  if (!techScoutingContext) {
    throw new Error('TechScoutingContext not found');
  }
  const { techResponse, setTechResponse, setSelectedCompany } = techScoutingContext;

  const { defaultProjectId } = useDefaultProjectContext();

  // Fetch documents for the library dropdown
  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const response = await axiosRequest(documentsURL, 'get');
        const sortedDocuments = response.data.sort((a: Document, b: Document) => {
          return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
        });
        setDocuments(sortedDocuments);
      } catch (err: any) {
        setError('Error fetching documents');
      }
    };
    fetchDocuments();
  }, [documentsURL]);

  const handleSubmit = async (e: React.FormEvent, generateReport: boolean = false) => {
    e.preventDefault();
    setError(null);
    setS3Url(null);
    setRetryAttempt(false);

    // Validate user input
    if (!query.trim() && !selectedDocumentId) {
      setErrorKey((prevKey) => prevKey + 1);
      setError('Please input a query or select a document.');
      return;
    }
    setLoading(true);

    // Clear previous response
    setTechResponse(null);

    try {
      const data = {
        query,
        threshold,
        generateReport,
        documentId: selectedDocumentId,
        useSBIR,
        projectId: defaultProjectId, // Include default project ID
      };

      if (!useSBIR && !generateReport) {
        // If useSBIR is false (Startup) and not generating a report, initiate streaming
        await initiateStreaming(data);
      } else {
        // For SBIR or report generation, make a normal POST request
        const res = await axiosRequest(techURL, 'post', data);
        setLoading(false);
        if (generateReport && res.data.s3_url) {
          setS3Url(res.data.s3_url);
        } else {
          setTechResponse(sanitizeResponse(res.data));
        }
      }
    } catch (error: any) {
      setLoading(false);
      setErrorKey((prevKey) => prevKey + 1);

      if (error.response && error.response.status === 401) {
        setError('Unauthorized. Please log in with a valid email and password.');
      } else {
        setError('Error fetching data. Please try again.');
      }

      console.error('Error fetching data:', error, 'url used:', techURL);
    }
  };

  const initiateStreaming = async (data: any, retry: boolean = false) => {
    try {
      // Make the POST request to get temp_token and possibly updated query
      const postResponse = await axiosRequest(techURL, 'post', data);
      const { temp_token, query: updatedQuery } = postResponse.data;

      // Use the updated query from the response if provided
      const finalQuery = updatedQuery || query;

      // Construct the EventSource URL with temp_token (JWT)
      const eventSourceUrl = `${techURL}?query=${encodeURIComponent(
        finalQuery
      )}&temp_token=${encodeURIComponent(temp_token)}`;

      const eventSource = new EventSource(eventSourceUrl);
      eventSourceRef.current = eventSource; // Store the EventSource in the ref

      eventSource.onopen = () => {
        console.log('Connection to server opened.');
      };

      eventSource.onmessage = (event) => {
        const newMessage = JSON.parse(event.data);
        console.log('Received message:', newMessage);

        if (newMessage.results) {
          handleStreamingResponse(newMessage);
        }
      };

      eventSource.onerror = (event) => {
        console.log('EventSource connection closed or error occurred:', event);
        // Close the EventSource
        eventSource.close();
        eventSourceRef.current = null;
        setLoading(false);
        // Do not set an error state here
      };

      // Handle the 'end' event if your server sends it
      eventSource.addEventListener('end', () => {
        console.log('Streaming ended.');
        eventSource.close();
        eventSourceRef.current = null;
        setLoading(false);
      });
    } catch (error: any) {
      if (!retryAttempt && !retry) {
        setRetryAttempt(true);
        console.warn('Retrying request after error...');
        await initiateStreaming(data, true);
      } else {
        setLoading(false);
        setError('An error occurred while initiating the request.');
      }
    }
  };

  const handleStreamingResponse = (message: any) => {
    setTechResponse((prevResponse: any) => {
      const newResults =
        prevResponse && prevResponse.results
          ? [...prevResponse.results, ...sanitizeResults(message.results)]
          : sanitizeResults(message.results);

      return {
        ...prevResponse,
        ...message,
        results: newResults,
        filtered_result_count: newResults.length,
      };
    });
  };

  const sanitizeResponse = (data: any) => {
    return {
      ...data,
      results: sanitizeResults(data.results),
    };
  };

  const sanitizeResults = (results: any[]) => {
    return results.map((result: any) => ({
      ...result,
      company: result.company.replace(/[|]/g, ''),
      abstract: result.abstract.replace(/[|]/g, ''),
    }));
  };

  const handleGenerateReport = async (e: React.FormEvent) => {
    await handleSubmit(e, true);
  };

  const handleThresholdChange = (value: string) => {
    switch (value) {
      case 'Specific':
        setThreshold(0.85);
        setSelectedPrecision('Specific');
        break;
      case 'General':
        setThreshold(0.82);
        setSelectedPrecision('General');
        break;
      case 'Broad':
        setThreshold(0.79);
        setSelectedPrecision('Broad');
        break;
      default:
        setThreshold(0.82);
        setSelectedPrecision('Precision');
    }
    setPrecisionDropdownOpen(false);
    // Move focus to the library dropdown button
    libraryDropdownButtonRef.current?.focus();
  };

  const handleDropdownButtonClick = () => {
    setPrecisionDropdownOpen(!precisionDropdownOpen);
    setLibraryDropdownOpen(false);
  };

  const handleLibraryDropdownClick = () => {
    setLibraryDropdownOpen(!libraryDropdownOpen);
    setPrecisionDropdownOpen(false);
  };

  const handleLibrarySelection = (
    event: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>,
    document: Document | null
  ) => {
    event.preventDefault();
    if ('stopPropagation' in event) event.stopPropagation();

    if (document) {
      setSelectedDocumentId(document.id);
      setSelectedDocumentTitle(document.documentTitle);
    } else {
      setSelectedDocumentId(null);
      setSelectedDocumentTitle('Library');
    }
    setLibraryDropdownOpen(false);

    // Delay focusing on the submit button to prevent Enter key press from triggering it
    setTimeout(() => {
      submitButtonRef.current?.focus();
    }, 0);
  };

  const handleDownload = (url: string) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = 'TechScouting_Report.docx';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const toggleSlider = () => {
    setUseSBIR((prev) => !prev); // Slider toggling
  };

  // Close dropdowns when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        precisionDropdownOpen &&
        precisionMenuRef.current &&
        !precisionMenuRef.current.contains(event.target as Node) &&
        !precisionDropdownButtonRef.current?.contains(event.target as Node)
      ) {
        setPrecisionDropdownOpen(false);
      }
      if (
        libraryDropdownOpen &&
        libraryDropdownMenuRef.current &&
        !libraryDropdownMenuRef.current.contains(event.target as Node) &&
        !libraryDropdownButtonRef.current?.contains(event.target as Node)
      ) {
        setLibraryDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [precisionDropdownOpen, libraryDropdownOpen]);

  // Consolidated useEffect for focusing error messages
  useEffect(() => {
    if (error && errorRef.current) {
      errorRef.current.focus();
    }
  }, [error]);

  // Keyboard navigation for the dropdown menus
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      // Handle precision dropdown navigation
      if (precisionDropdownOpen && precisionMenuRef.current) {
        const items = precisionMenuRef.current.querySelectorAll('div[role="menuitem"]');
        const activeIndex = Array.from(items).findIndex((item) => item === document.activeElement);

        switch (event.key) {
          case 'ArrowDown':
            event.preventDefault();
            const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
            (items[nextIndex] as HTMLElement).focus();
            break;
          case 'ArrowUp':
            event.preventDefault();
            const prevIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
            (items[prevIndex] as HTMLElement).focus();
            break;
          case 'Enter':
            event.preventDefault();
            if (activeIndex !== -1) {
              (items[activeIndex] as HTMLElement).click();
            }
            break;
          case 'Escape':
            setPrecisionDropdownOpen(false);
            precisionDropdownButtonRef.current?.focus();
            break;
          case 'Tab':
            if (!event.shiftKey && activeIndex === items.length - 1) {
              event.preventDefault();
              setPrecisionDropdownOpen(false);
              libraryDropdownButtonRef.current?.focus();
            } else if (event.shiftKey && activeIndex === 0) {
              event.preventDefault();
              setPrecisionDropdownOpen(false);
              precisionDropdownButtonRef.current?.focus();
            }
            break;
        }
      }

      // Handle library dropdown navigation
      if (libraryDropdownOpen && libraryDropdownMenuRef.current) {
        const items = libraryDropdownMenuRef.current.querySelectorAll('div[role="menuitem"]');
        const activeIndex = Array.from(items).findIndex((item) => item === document.activeElement);

        switch (event.key) {
          case 'ArrowDown':
            event.preventDefault();
            const nextIdx = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
            (items[nextIdx] as HTMLElement).focus();
            break;
          case 'ArrowUp':
            event.preventDefault();
            const prevIdx = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
            (items[prevIdx] as HTMLElement).focus();
            break;
          case 'Enter':
            event.preventDefault();
            if (activeIndex !== -1) {
              (items[activeIndex] as HTMLElement).click();
            }
            break;
          case 'Escape':
            setLibraryDropdownOpen(false);
            libraryDropdownButtonRef.current?.focus();
            break;
          case 'Tab':
            if (!event.shiftKey && activeIndex === items.length - 1) {
              event.preventDefault();
              setLibraryDropdownOpen(false);
              submitButtonRef.current?.focus();
            } else if (event.shiftKey && activeIndex === 0) {
              event.preventDefault();
              setLibraryDropdownOpen(false);
              libraryDropdownButtonRef.current?.focus();
            }
            break;
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [precisionDropdownOpen, libraryDropdownOpen]);

  // Set focus to the first menu item when dropdowns open
  useEffect(() => {
    if (precisionDropdownOpen && precisionMenuRef.current) {
      const firstItem = precisionMenuRef.current.querySelector('div[role="menuitem"]');
      (firstItem as HTMLElement)?.focus();
    }
    if (libraryDropdownOpen && libraryDropdownMenuRef.current) {
      const firstItem = libraryDropdownMenuRef.current.querySelector('div[role="menuitem"]');
      (firstItem as HTMLElement)?.focus();
    }
  }, [precisionDropdownOpen, libraryDropdownOpen]);

  // Cleanup EventSource when component unmounts
  useEffect(() => {
    return () => {
      if (eventSourceRef.current) {
        eventSourceRef.current.close();
        eventSourceRef.current = null;
      }
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Technology Scouting</title>
      </Helmet>
      <nav aria-label="Primary Navigation" className="primary-nav"></nav>
      <main role="main" tabIndex={-1} className="main-content">
        <div className={`tech-scouting-container ${isDarkMode ? 'dark' : 'light'}`}>
          <h1 tabIndex={0} role="heading" aria-level={1} className={isDarkMode ? 'dark' : 'light'}>
            Technology Scouting
          </h1>
          <p tabIndex={0} role="note" className={isDarkMode ? 'dark' : 'light'}>
            Performs market research to find sole-source eligible companies for an inputted technology or selected document.
          </p>
          <form className="tech-scouting-form" onSubmit={(e) => handleSubmit(e)}>
            <div className="tech-scouting-input-container" ref={dropdownRef}>
              <label htmlFor="tech-scouting-query" className="visually-hidden">
                Search Query
              </label>
              <input
                id="tech-scouting-query"
                type="text"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                placeholder="Ex: Navigation in GPS-denied Environments"
                autoComplete="on"
                aria-describedby="error-message"
                className={`${isDarkMode ? 'dark' : 'light'}`}
                disabled={loading}
              />

              <div className="tech-scouting-buttons-container">
                {/* Precision Dropdown Button with Tooltip */}
                <div className="tech-scouting-button-with-tooltip">
                  <button
                    type="button"
                    className={`tech-scouting-dropdown-button ${isDarkMode ? 'dark' : 'light'}`}
                    onClick={handleDropdownButtonClick}
                    aria-expanded={precisionDropdownOpen}
                    aria-controls="tech-scouting-precision-dropdown-menu"
                    ref={precisionDropdownButtonRef}
                    disabled={loading}
                  >
                    &#9776; {selectedPrecision}
                  </button>
                  <span className="tech-scouting-tooltip-text">
                    Limits number of results based on relevance
                  </span>
                </div>

                {/* Library Dropdown Button with Tooltip */}
                <div className="tech-scouting-button-with-tooltip">
                  <button
                    type="button"
                    className={`tech-scouting-library-dropdown-button ${isDarkMode ? 'dark' : 'light'}`}
                    onClick={handleLibraryDropdownClick}
                    aria-expanded={libraryDropdownOpen}
                    aria-controls="tech-scouting-library-dropdown-menu"
                    ref={libraryDropdownButtonRef}
                    disabled={loading}
                  >
                    &#128194; {selectedDocumentTitle}
                  </button>
                  <span className="tech-scouting-tooltip-text">Select a document from your library</span>
                </div>

                {/* Slider for SBIR/Startup */}
                <div className="tech-scouting-slider-container">
                  <label className="tech-scouting-switch">
                    <span className="visually-hidden">SBIR Toggle</span>
                    <input
                      type="checkbox"
                      checked={useSBIR}
                      onChange={toggleSlider}
                      disabled={loading}
                    />
                    <span className="tech-scouting-slider tech-scouting-round"></span>
                  </label>
                  <span className="tech-scouting-slider-label">
                    {useSBIR ? 'SBIR' : 'Startup'}
                  </span>
                </div>

                {/* Submit Button */}
                <button
                  type={loading ? 'button' : 'submit'}
                  className={`tech-scouting-submit-button ${isDarkMode ? 'dark' : 'light'}`}
                  aria-label={loading ? 'Cancel' : 'Submit Query'}
                  ref={submitButtonRef}
                  onClick={(e) => {
                    if (loading) {
                      e.preventDefault();
                      // Handle cancellation
                      if (eventSourceRef.current) {
                        eventSourceRef.current.close();
                        eventSourceRef.current = null;
                      }
                      setLoading(false);
                      // Do not clear techResponse to keep accumulated results
                      // Optionally, notify the backend about the cancellation if necessary
                    }
                  }}
                >
                  {loading ? '✕' : '➤'}
                </button>
              </div>

              {/* Precision Dropdown Menu - Always Rendered */}
              <div
                className={`tech-scouting-dropdown-menu ${isDarkMode ? 'dark' : 'light'} ${
                  precisionDropdownOpen ? 'open' : 'closed'
                }`}
                id="tech-scouting-precision-dropdown-menu"
                role="menu"
                ref={precisionMenuRef}
                aria-hidden={!precisionDropdownOpen}
              >
                <div
                  tabIndex={0}
                  role="menuitem"
                  className={`tech-scouting-dropdown-item ${isDarkMode ? 'dark' : 'light'}`}
                  onClick={() => handleThresholdChange('Specific')}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      handleThresholdChange('Specific');
                    }
                  }}
                >
                  Specific
                </div>
                <div
                  tabIndex={0}
                  role="menuitem"
                  className={`tech-scouting-dropdown-item ${isDarkMode ? 'dark' : 'light'}`}
                  onClick={() => handleThresholdChange('General')}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      handleThresholdChange('General');
                    }
                  }}
                >
                  General
                </div>
                <div
                  tabIndex={0}
                  role="menuitem"
                  className={`tech-scouting-dropdown-item ${isDarkMode ? 'dark' : 'light'}`}
                  onClick={() => handleThresholdChange('Broad')}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      handleThresholdChange('Broad');
                    }
                  }}
                >
                  Broad
                </div>
              </div>

              {/* Library Dropdown Menu - Always Rendered */}
              <div
                className={`tech-scouting-library-dropdown-menu ${isDarkMode ? 'dark' : 'light'} ${
                  libraryDropdownOpen ? 'open' : 'closed'
                }`}
                id="tech-scouting-library-dropdown-menu"
                role="menu"
                ref={libraryDropdownMenuRef}
                aria-hidden={!libraryDropdownOpen}
              >
                <div
                  role="menuitem"
                  tabIndex={0}
                  onClick={(event) => handleLibrarySelection(event, null)}
                  className={`tech-scouting-dropdown-item ${isDarkMode ? 'dark' : 'light'}`}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      handleLibrarySelection(event, null);
                    }
                  }}
                >
                  -- No Document --
                </div>
                {documents.map((document) => (
                  <div
                    key={document.id}
                    role="menuitem"
                    tabIndex={0}
                    onClick={(event) => handleLibrarySelection(event, document)}
                    className={`tech-scouting-dropdown-item ${isDarkMode ? 'dark' : 'light'}`}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        handleLibrarySelection(event, document);
                      }
                    }}
                  >
                    {document.documentTitle}
                  </div>
                ))}
              </div>
            </div>

            {/* Error Message */}
            <div
              id="error-message"
              key={errorKey}
              className={`tech-scouting-error ${error ? 'visible' : 'hidden'} ${isDarkMode ? 'dark' : 'light'
                }`}
              aria-live="assertive"
              role="alert"
              ref={errorRef}
              tabIndex={-1}
            >
              {error}
            </div>
          </form>

          {loading && <Loading />}

          {techResponse && (
            <div className={`tech-scouting-response ${isDarkMode ? 'dark' : 'light'}`}>
              {s3Url && (
                <div className="tech-scouting-report-generated-header">
                  <h2
                    className={`tech-scouting-report-generated-header ${isDarkMode ? 'dark' : 'light'}`}
                    tabIndex={0}
                    role="heading"
                    aria-level={2}
                  >
                    Report Generated!
                  </h2>
                  <div className="tech-scouting-report-generated-button">
                    <button
                      onClick={() => handleDownload(s3Url)}
                      className={`tech-scouting-download-button ${isDarkMode ? 'dark' : 'light'}`}
                    >
                      Download Report
                    </button>
                  </div>
                </div>
              )}
              <div className="tech-scouting-results-header">
                <h2 tabIndex={0} role="heading" aria-level={2} className={isDarkMode ? 'dark' : 'light'}>
                  Results: {techResponse.results.length}
                </h2>
                <button
                  className={`tech-scouting-generate-report-button ${isDarkMode ? 'dark' : 'light'}`}
                  onClick={handleGenerateReport}
                >
                  Generate Report
                </button>
              </div>
              <div className="tech-scouting-results-container">
                {techResponse.results.map((result: any, index: number) => (
                  <div key={index} className={`tech-scouting-result-card ${isDarkMode ? 'dark' : 'light'}`}>
                    <h3
                      id={`result-heading-${index}`}
                      tabIndex={0}
                      role="heading"
                      aria-level={3}
                      className={isDarkMode ? 'dark' : 'light'}
                    >
                      <button
                        onClick={() => {
                          setSelectedCompany(result);
                          setSection('CompanyDetails');
                        }}
                        className={`company-button ${isDarkMode ? 'dark' : 'light'}`}
                      >
                        {index + 1}: {result.company}
                      </button>
                    </h3>
                    <p tabIndex={0} role="document" className={isDarkMode ? 'dark' : 'light'}>
                      {result.abstract}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </main>
    </>
  );
};

export default TechScouting;
