import React, { useState, useEffect, useRef, useContext } from 'react';
import axiosRequest from '../utils/axiosRequest';
import './style/MOUGeneration.css';
import Loading from './loading/Loading';
import { Document } from '../types';
import { Helmet } from 'react-helmet';
import { DefaultProjectContext } from '../ContextStore';

interface MOUGenerationProps {
  isDarkMode: boolean;
}

const MOUGeneration: React.FC<MOUGenerationProps> = ({ isDarkMode }) => {
  const [documentContext, setDocumentContext] = useState<string>('');
  const [organization, setOrganization] = useState<string>('');
  const [endUser, setEndUser] = useState<string>('');
  const [company, setCompany] = useState<string>('');
  const [fileBase64, setFileBase64] = useState<string | null>(null);
  const [s3Url, setS3Url] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [errorKey, setErrorKey] = useState<number>(0);
  const [selectedFileName, setSelectedFileName] = useState<string>('Choose File');

  // Library dropdown state
  const [documents, setDocuments] = useState<Document[]>([]);
  const [selectedDocumentId, setSelectedDocumentId] = useState<string | null>(null);
  const [selectedDocumentTitle, setSelectedDocumentTitle] = useState<string>('Library');
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

  const MOUURL = process.env.REACT_APP_MOU_GENERATION_API_URL;
  const documentsURL = process.env.REACT_APP_DOCUMENTS_API_URL;

  const dropdownRef = useRef<HTMLDivElement>(null);
  const errorRef = useRef<HTMLDivElement>(null);
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const libraryDropdownMenuRef = useRef<HTMLDivElement>(null);
  const libraryDropdownButtonRef = useRef<HTMLButtonElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  if (!MOUURL || !documentsURL) {
    throw new Error('API URLs are not defined');
  }

  const defaultProjectContext = useContext(DefaultProjectContext);
  if (!defaultProjectContext) {
    throw new Error('DefaultProjectContext not found');
  }
  const { defaultProjectId } = defaultProjectContext;

  // Fetch documents for the library dropdown
  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const response = await axiosRequest(documentsURL, 'get');
        const sortedDocuments = response.data.sort((a: Document, b: Document) => {
          return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
        });
        setDocuments(sortedDocuments);
      } catch (err: any) {
        setError('Error fetching documents');
      }
    };
    fetchDocuments();
  }, [documentsURL]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setSelectedFileName(file.name);

      const reader = new FileReader();
      const fileExtension = file.name.split('.').pop()?.toLowerCase();

      reader.onload = () => {
        const binaryStr = reader.result as string;
        if (fileExtension === 'pdf' || fileExtension === 'docx') {
          setFileBase64(btoa(binaryStr));
          setError(null);
        } else {
          setError('Unsupported file format. Please upload a PDF or DOCX file.');
          setFileBase64(null);
        }
      };

      if (fileExtension === 'pdf' || fileExtension === 'docx') {
        reader.readAsBinaryString(file);
      } else {
        setError('Unsupported file format. Please upload a PDF or DOCX file.');
      }
    } else {
      setSelectedFileName('Choose File');
      setFileBase64(null);
    }
  };

  const handleSubmit = async (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    setLoading(true);
    setError(null);

    // Validate mandatory fields
    if (!organization.trim() || !endUser.trim() || !company.trim()) {
      setErrorKey((prevKey) => prevKey + 1);
      setError('Please fill out all mandatory fields: Organization, End User, and Company.');
      setLoading(false);
      return;
    }

    // Validate document context or file upload or selected document
    if (!documentContext.trim() && !fileBase64 && !selectedDocumentId) {
      setErrorKey((prevKey) => prevKey + 1);
      setError('Please input a document context, upload a file, or select a document.');
      setLoading(false);
      return;
    }

    try {
      // Prepare the data to send
      const data = {
        document_context: documentContext,
        organization: organization,
        company: company,
        file_base64: fileBase64,
        endUser: endUser,
        documentId: selectedDocumentId,
        project_id: defaultProjectId, // Include the default project ID
      };

      const res = await axiosRequest(MOUURL, 'post', data);
      setS3Url(res.data.s3_url);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      setErrorKey((prevKey) => prevKey + 1);

      if (error.response && error.response.status === 401) {
        setError('Unauthorized. Please log in with a valid email and password.');
      } else {
        setError('Error generating document.');
      }

      console.error('Error generating document:', error);
    }
  };

  // Handle key down in textarea to submit form on Enter key press
  const handleTextareaKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit();
    }
  };

  // Focus the error message when it appears
  useEffect(() => {
    if (error && errorRef.current) {
      errorRef.current.focus();
    }
  }, [error]);

  // Set focus to the first menu item when the library dropdown opens
  useEffect(() => {
    if (dropdownOpen && libraryDropdownMenuRef.current) {
      const firstItem = libraryDropdownMenuRef.current.querySelector('div[role="menuitem"]');
      (firstItem as HTMLElement)?.focus();
    }
  }, [dropdownOpen]);

  // Handle clicks outside the dropdown to close it
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  // Keyboard navigation for the dropdown menu
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (dropdownOpen && libraryDropdownMenuRef.current) {
        const items = libraryDropdownMenuRef.current.querySelectorAll('div[role="menuitem"]');
        const activeIndex = Array.from(items).findIndex((item) => item === document.activeElement);

        switch (event.key) {
          case 'ArrowDown':
            event.preventDefault();
            const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
            (items[nextIndex] as HTMLElement).focus();
            break;
          case 'ArrowUp':
            event.preventDefault();
            const prevIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
            (items[prevIndex] as HTMLElement).focus();
            break;
          case 'Enter':
            event.preventDefault();
            if (activeIndex !== -1) {
              (items[activeIndex] as HTMLElement).click();
            }
            break;
          case 'Tab':
            if (event.shiftKey) {
              if (activeIndex === 0) {
                event.preventDefault();
                setDropdownOpen(false);
                libraryDropdownButtonRef.current?.focus();
              }
            } else {
              if (activeIndex === items.length - 1) {
                event.preventDefault();
                setDropdownOpen(false);
                submitButtonRef.current?.focus();
              }
            }
            break;
          case 'Escape':
            setDropdownOpen(false);
            libraryDropdownButtonRef.current?.focus();
            break;
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [dropdownOpen]);

  // Handle library dropdown open/close and selection
  const handleLibraryDropdownClick = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleLibrarySelection = (
    event: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>,
    document: Document | null
  ) => {
    event.preventDefault();
    if ('stopPropagation' in event) event.stopPropagation();

    if (document) {
      setSelectedDocumentId(document.id);
      setSelectedDocumentTitle(document.documentTitle);
    } else {
      setSelectedDocumentId(null);
      setSelectedDocumentTitle('Library');
    }
    setDropdownOpen(false);

    // Delay focusing on the submit button to prevent Enter key press from triggering it
    setTimeout(() => {
      submitButtonRef.current?.focus();
    }, 0);
  };

  const handleDownload = (url: string) => {
    // Create an anchor element to trigger the download
    const link = document.createElement('a');
    link.href = url;
    link.download = 'Generated_Document.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <Helmet>
        <title>MOU Generation</title>
      </Helmet>
      <nav aria-label="Primary Navigation" className="primary-nav"></nav>
      <main role="main" tabIndex={-1} className="main-content">
        <div className={`mou-generation-container ${isDarkMode ? 'dark' : 'light'}`}>
          <h1 tabIndex={0} role="heading" aria-level={1} className={isDarkMode ? 'dark' : 'light'}>
            MOU Generation
          </h1>
          <p tabIndex={0} role="note" className={isDarkMode ? 'dark' : 'light'}>
            Generates Memorandums of Understanding based on provided context or uploaded PDF.
          </p>
          <form className="mou-generation-form" onSubmit={handleSubmit}>
            <div className="mou-generation-input-container" ref={dropdownRef}>
              <label htmlFor="mou-organization" className="visually-hidden">
                Organization
              </label>
              <input
                id="mou-organization"
                type="text"
                value={organization}
                onChange={(e) => setOrganization(e.target.value)}
                placeholder="Enter the organization Ex: Northspark Defense Laboratory"
                autoComplete="off"
                aria-describedby="error-message"
                className={`small-input ${isDarkMode ? 'dark' : 'light'}`}
              />
              <label htmlFor="mou-end-user" className="visually-hidden">
                End User
              </label>
              <input
                id="mou-end-user"
                type="text"
                value={endUser}
                onChange={(e) => setEndUser(e.target.value)}
                placeholder="Enter the end user Ex: AtomBeam"
                autoComplete="off"
                aria-describedby="error-message"
                className={`small-input ${isDarkMode ? 'dark' : 'light'}`}
              />
              <label htmlFor="mou-company" className="visually-hidden">
                Company
              </label>
              <input
                id="mou-company"
                type="text"
                value={company}
                onChange={(e) => setCompany(e.target.value)}
                placeholder="Enter the company Ex: Acme Corp"
                autoComplete="off"
                aria-describedby="error-message"
                className={`small-input ${isDarkMode ? 'dark' : 'light'}`}
              />
              <div className="textarea-with-buttons">
                <label htmlFor="mou-generation-context" className="visually-hidden">
                  Document Context
                </label>
                <textarea
                  id="mou-generation-context"
                  value={documentContext}
                  onChange={(e) => setDocumentContext(e.target.value)}
                  onKeyDown={handleTextareaKeyDown}
                  placeholder="Enter the document context Ex: Roadrunner deterrence technologies"
                  autoComplete="off"
                  aria-describedby="error-message"
                  className={`mou-generation-document-context-textarea ${isDarkMode ? 'dark' : 'light'}`}
                />
                <div className="mou-generation-buttons-container">
                  {/* File Input Button with Tooltip */}
                  <div className="mou-generation-button-with-tooltip">
                    <button
                      type="button"
                      className={`mou-generation-file-input-button ${isDarkMode ? 'dark' : 'light'}`}
                      onClick={() => fileInputRef.current?.click()}
                      aria-describedby="file-input-tooltip"
                    >
                      &#9776; {selectedFileName}
                    </button>
                    <span id="file-input-tooltip" className="mou-generation-tooltip-text">
                      Upload a PDF or DOCX file from your computer to be used as document context
                    </span>
                  </div>

                  {/* Library Dropdown Button with Tooltip */}
                  <div className="mou-generation-button-with-tooltip">
                    <button
                      type="button"
                      className={`mou-generation-dropdown-button ${isDarkMode ? 'dark' : 'light'}`}
                      onClick={handleLibraryDropdownClick}
                      aria-expanded={dropdownOpen}
                      aria-controls="mou-generation-library-dropdown-menu"
                      ref={libraryDropdownButtonRef}
                    >
                      &#128194; {selectedDocumentTitle}
                    </button>
                    <span className="mou-generation-tooltip-text">Generate MOU for technology in file</span>
                  </div>
                </div>

                {/* Submit Button */}
                <button
                  type="submit"
                  className={`mou-generation-submit-button ${isDarkMode ? 'dark' : 'light'}`}
                  aria-label="Submit Button"
                  ref={submitButtonRef}
                >
                  &#x27A4;
                </button>

                {/* Library Dropdown Menu - Always Rendered */}
                <div
                  className={`mou-generation-library-dropdown-menu ${isDarkMode ? 'dark' : 'light'} ${dropdownOpen ? 'open' : 'closed'}`}
                  id="mou-generation-library-dropdown-menu"
                  role="menu"
                  ref={libraryDropdownMenuRef}
                  aria-hidden={!dropdownOpen}
                >
                  <div
                    role="menuitem"
                    tabIndex={0}
                    onClick={(event) => handleLibrarySelection(event, null)}
                    className={`mou-generation-dropdown-item ${isDarkMode ? 'dark' : 'light'}`}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        handleLibrarySelection(event, null);
                      }
                    }}
                  >
                    -- No Document --
                  </div>
                  {documents.map((document) => (
                    <div
                      key={document.id}
                      role="menuitem"
                      tabIndex={0}
                      onClick={(event) => handleLibrarySelection(event, document)}
                      className={`mou-generation-dropdown-item ${isDarkMode ? 'dark' : 'light'}`}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          handleLibrarySelection(event, document);
                        }
                      }}
                    >
                      {document.documentTitle}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {/* Error Message */}
            <div
              id="error-message"
              key={errorKey}
              className={`mou-generation-error ${error ? 'visible' : 'hidden'} ${isDarkMode ? 'dark' : 'light'}`}
              aria-live="assertive"
              role="alert"
              ref={errorRef}
              tabIndex={-1}
            >
              {error}
            </div>
          </form>
          {loading && <Loading />}
          {s3Url && (
            <div className={`mou-generation-response ${isDarkMode ? 'dark' : 'light'}`}>
              <h2 tabIndex={0} role="heading" aria-level={2}>
                Document Generated!
              </h2>
              <button
                onClick={() => handleDownload(s3Url)}
                className={`mou-generation-download-button ${isDarkMode ? 'dark' : 'light'}`}
              >
                Download Document
              </button>
            </div>
          )}
        </div>
      </main>
    </>
  );
};

export default MOUGeneration;
