// SignIn.tsx
import React, { useState } from 'react';
import axiosRequest from '../utils/axiosRequest'; // Adjust the import path as needed
import './style/SignIn.css';
import { Helmet } from 'react-helmet';

interface SignInProps {
  onSignIn: () => void;
  setSection: (section: string) => void;
}

const SignIn: React.FC<SignInProps> = ({ onSignIn, setSection }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [session, setSession] = useState('');
  const [error, setError] = useState('');
  const [needsConfirmation, setNeedsConfirmation] = useState(false);
  const [confirmationCode, setConfirmationCode] = useState('');
  const [resendSuccessMessage, setResendSuccessMessage] = useState('');

  const authURL = process.env.REACT_APP_SIGNIN_API_URL;
  if (!authURL) {
    throw new Error('REACT_APP_SIGNIN_API_URL is not defined');
  }

  const handleSignIn = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setResendSuccessMessage('');

    try {
      const response = await axiosRequest(authURL, 'post', {
        email,
        password,
      });

      if (response.data.challenge === 'NEW_PASSWORD_REQUIRED') {
        setSession(response.data.session);
        setError('New password required');
      } else {
        onSignIn(); // Update authentication state
      }
    } catch (err: any) {
      const errorMessage = err.response?.data?.error || 'Sign-in failed';
      setError(errorMessage);

      if (
        err.response?.data?.code === 'UserNotConfirmedException' ||
        errorMessage.includes('User is not confirmed')
      ) {
        setNeedsConfirmation(true);
      }
    }
  };

  const handleConfirmSignUp = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setResendSuccessMessage('');

    try {
      const response = await axiosRequest(`${authURL}verify-email/`, 'post', {
        email,
        confirmationCode,
      });

      if (response.status === 200) {
        const signInResponse = await axiosRequest(authURL, 'post', {
          email,
          password,
        });

        if (signInResponse.data.challenge === 'NEW_PASSWORD_REQUIRED') {
          setSession(signInResponse.data.session);
          setError('New password required');
        } else {
          onSignIn();
        }
      }
    } catch (err: any) {
      setError(err.response?.data?.error || 'Confirmation failed. Please try again.');
    }
  };

  const handleResendConfirmationCode = async () => {
    setError('');
    setResendSuccessMessage('');

    try {
      const response = await axiosRequest(`${authURL}resend-code/`, 'post', {
        email,
      });

      if (response.status === 200) {
        setResendSuccessMessage('Confirmation code resent successfully. Please check your email.');
      }
    } catch (err: any) {
      setError(err.response?.data?.error || 'Failed to resend confirmation code.');
    }
  };

  const handleNewPassword = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setResendSuccessMessage('');

    try {
      await axiosRequest(authURL + 'respond/', 'post', {
        email,
        newPassword,
        session,
      });

      onSignIn();
    } catch (err: any) {
      setError(err.response?.data?.error || 'Password update failed');
    }
  };

  return (
    <>
      <Helmet>
        <title>Sign In</title>
      </Helmet>
      <a href="#main-content" className="skip-link">
        Skip to main content
      </a>
      <nav aria-label="Primary Navigation" className="primary-nav">
        {/* Navigation items go here */}
      </nav>
      <div className="container">
        <div className="sign-in-form">
          <img src="/InnoScaleLogo1Cropped.png" alt="InnoScale company logo" />
          <main id="main-content" role="main" tabIndex={-1} className="main-content">
            <h1 className="sr-only">Sign In</h1>
            {!needsConfirmation && !session && (
              <form onSubmit={handleSignIn}>
                <label htmlFor="email">Email:</label>
                <input
                  id="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  autoComplete="email"
                />
                <label htmlFor="password">Password:</label>
                <input
                  id="password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  autoComplete="current-password"
                />
                <button type="submit">Sign In</button>
              </form>
            )}
            {needsConfirmation && (
              <div>
                <p className="confirmation-message">
                  Your account is not confirmed. Please enter the confirmation code sent to {email}.
                </p>
                <form onSubmit={handleConfirmSignUp}>
                  <label htmlFor="confirmationCode">Confirmation Code:</label>
                  <input
                    id="confirmationCode"
                    type="text"
                    value={confirmationCode}
                    onChange={(e) => setConfirmationCode(e.target.value)}
                    required
                  />
                  <button type="submit">Confirm Account</button>
                </form>
                <button onClick={handleResendConfirmationCode} className="resend-button">
                  Resend Confirmation Code
                </button>
              </div>
            )}
            {session && (
              <div>
                <form onSubmit={handleNewPassword}>
                  <label htmlFor="new-password">New Password:</label>
                  <input
                    id="new-password"
                    type="password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    required
                    autoComplete="new-password"
                  />
                  <button type="submit">Set New Password</button>
                </form>
              </div>
            )}            
            {/* Sign Up Button */}
            <button onClick={() => setSection('SignUp')} className="sign-up-button">
              Sign Up
            </button>
            
            {/* Move Error and Success Messages Below the Buttons */}
            {error && <p className="error-message">{error}</p>}
            {resendSuccessMessage && <p className="success-message">{resendSuccessMessage}</p>}
          </main>
        </div>
      </div>
    </>
  );
};

export default SignIn;
