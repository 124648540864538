// Navbar.tsx

import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMagnifyingGlass,
  faCompass,
  faFileLines,
  faRobot,
  faBars,
  faCog,
  faLightbulb,
  faSitemap
} from '@fortawesome/free-solid-svg-icons';
import './style/Navbar.css';
import { useDefaultProjectContext } from '../ContextStore'; // Import the custom hook
import { library } from 'ionicons/icons';

interface NavbarProps {
  setSection: (section: string) => void;
  isInProposalsGroup: boolean;
  userEmail: string;
  isDarkMode: boolean; // Add isDarkMode prop
}

const Navbar: React.FC<NavbarProps> = ({ setSection, isInProposalsGroup, userEmail, isDarkMode }) => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Consume DefaultProjectContext using the custom hook
  const { defaultProjectName } = useDefaultProjectContext();

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleMenuItemClick = (section: string) => {
    setSection(section);
    setIsMenuOpen(false);
  };

  return (
    <div className={`navbar ${isDarkMode ? 'dark' : 'light'}`}>
      <button
        className="navbar-logo-button"
        onClick={() => setSection('TechScouting')}
        aria-label="Navigate to Technology Scouting"
      >
        <img
          src="/InnoScaleLogo1Cropped.png"
          alt="InnoScale company logo"
          className="navbar-logo"
        />
      </button>

      {isSmallScreen ? (
        <>
          <button className="navbar-menu-toggle-button" onClick={handleMenuToggle} aria-label="Toggle menu">
            <FontAwesomeIcon icon={faBars} className="navbar-icon" />
          </button>

          {isMenuOpen && (
            <div className={`navbar-menu open ${isDarkMode ? 'dark' : 'light'}`}>
              {isInProposalsGroup ? (
                <>
                  <button onClick={() => handleMenuItemClick('Proposals')}>Proposal Evaluator</button>
                  <button onClick={() => handleMenuItemClick('Documents')}>Library</button>
                </>
              ) : (
                <>
                  <button onClick={() => handleMenuItemClick('ATOAssistant')}>ATO Assistant</button>
                  <button onClick={() => handleMenuItemClick('TechScouting')}>Technology Scouting</button>
                  <button onClick={() => handleMenuItemClick('Proposals')}>Proposal Evaluator</button>
                  <button onClick={() => handleMenuItemClick('StakeholderIdentification')}>
                    Stakeholder Identification
                  </button>
                  <button onClick={() => handleMenuItemClick('ProjectSupport')}>Project Support</button>
                  <button onClick={() => handleMenuItemClick('MOUGeneration')}>MOU Generation</button>
                  <button onClick={() => handleMenuItemClick('Documents')}>Library</button>
                  <button onClick={() => handleMenuItemClick('ProjectDashboard')}>New Project</button>
                </>
              )}
            </div>
          )}
        </>
      ) : (
        <>
          <div className={`navbar-menu ${isDarkMode ? 'dark' : 'light'}`}>
            {isInProposalsGroup ? (
              <>
                <button onClick={() => setSection('MOUGeneration')}>
                  <FontAwesomeIcon icon={faFileLines} className="navbar-icon" /> Proposals
                </button>
                <button onClick={() => setSection('Documents')}>
                  <img src={library} alt="Library" className="navbar-icon" /> Library
                </button>
              </>
            ) : (
              <>
                <button onClick={() => setSection('ATOAssistant')}>
                  <FontAwesomeIcon icon={faRobot} className="navbar-icon" /> ATO Assistant
                </button>
                <button onClick={() => setSection('TechScouting')}>
                  <FontAwesomeIcon icon={faMagnifyingGlass} className="navbar-icon" /> Technology Scouting
                </button>
                <button onClick={() => setSection('Proposals')}>
                  <FontAwesomeIcon icon={faLightbulb} className="navbar-icon" /> Proposal Evaluator
                </button>
                <button onClick={() => setSection('StakeholderIdentification')}>
                  <FontAwesomeIcon icon={faCompass} className="navbar-icon" /> Stakeholder Identification
                </button>
                <button onClick={() => setSection('ProjectSupport')}>
                  <FontAwesomeIcon icon={faFileLines} className="navbar-icon" /> Project Support
                </button>
                <button onClick={() => setSection('MOUGeneration')}>
                  <FontAwesomeIcon icon={faFileLines} className="navbar-icon" /> MOU Generation
                </button>
                <button onClick={() => setSection('Documents')}>
                  <img src={library} alt="Library Bookshelf" className="navbar-icon" /> Library
                </button>
                <button onClick={() => setSection('NewProject')}>
                  <FontAwesomeIcon icon={faLightbulb} className="navbar-icon" /> New Project
                </button>
              </>
            )}
          </div>
          {/* Display user's email and default project on the navbar */}
          <div className="navbar-email" onClick={() => setSection('Settings')}>
            <div className="navbar-email-info">
              <span className="navbar-email-text">{userEmail}</span>
              {defaultProjectName && (
                <span className="navbar-default-project-name">{defaultProjectName}</span>
              )}
            </div>
            <FontAwesomeIcon icon={faCog} className="navbar-settings-icon" />
          </div>
        </>
      )}
    </div>
  );
};

export default Navbar;
