// Library.tsx

import React, { useState, useEffect, useRef, useCallback, useContext } from 'react';
import axiosRequest from '../utils/axiosRequest';
import './style/Library.css';
import { Helmet } from 'react-helmet';
import { Document, Project, Thread } from '../types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import ProjectDetail from './ProjectDetail';
import LibraryModal from './LibraryModal';
import { TechScoutingContext, StakeholderIdentificationContext } from '../ContextStore';

interface LibraryProps {
  setSection: React.Dispatch<React.SetStateAction<string>>;
  isInProposalsGroup: boolean;
  isDarkMode: boolean;
  selectedLibraryProjectId: string | null; // New prop to manage selected project
  setSelectedLibraryProjectId: React.Dispatch<React.SetStateAction<string | null>>; // New prop to manage selected project
}

const Library: React.FC<LibraryProps> = ({
  setSection,
  isInProposalsGroup,
  isDarkMode,
  selectedLibraryProjectId,
  setSelectedLibraryProjectId,
}) => {
  // State for Documents
  const [documents, setDocuments] = useState<Document[]>([]);
  const [file, setFile] = useState<File | null>(null);
  const [documentType, setDocumentType] = useState<string>('');
  const [docError, setDocError] = useState<string | null>(null);
  const [docSuccessMessage, setDocSuccessMessage] = useState<string | null>(null);
  const [showDocForm, setShowDocForm] = useState<boolean>(false);
  const [editingDocumentId, setEditingDocumentId] = useState<string | null>(null);
  const [newDocumentTitle, setNewDocumentTitle] = useState<string>('');

  // State for Threads
  const [threads, setThreads] = useState<Thread[]>([]);
  const [threadError, setThreadError] = useState<string | null>(null);
  const [threadSuccessMessage, setThreadSuccessMessage] = useState<string | null>(null);
  const [editingThreadId, setEditingThreadId] = useState<string | null>(null);
  const [newThreadTitle, setNewThreadTitle] = useState<string>('');

  // Toggle between Threads and Documents
  const [activeTab, setActiveTab] = useState<'threads' | 'documents'>('threads');

  // State for Projects
  const [projects, setProjects] = useState<Project[]>([]);
  const [projError, setProjError] = useState<string | null>(null);
  const [projSuccessMessage, setProjSuccessMessage] = useState<string | null>(null);
  const [showProjForm, setShowProjForm] = useState<boolean>(false);
  const [newProjectName, setNewProjectName] = useState<string>('');
  const [newProjectDescription, setNewProjectDescription] = useState<string>('');
  const [editingProjectId, setEditingProjectId] = useState<string | null>(null);
  const [newProjectTitle, setNewProjectTitle] = useState<string>('');

  // Selected project within the modal
  const [modalSelectedProjectId, setModalSelectedProjectId] = useState<string>('');

  const [itemToAddId, setItemToAddId] = useState<string>('');
  const [itemTypeToAdd, setItemTypeToAdd] = useState<'document' | 'thread' | null>(null);
  const [addToProjectError, setAddToProjectError] = useState<string | null>(null);
  const [addToProjectSuccessMessage, setAddToProjectSuccessMessage] = useState<string | null>(null);
  const [showAddToProjectModal, setShowAddToProjectModal] = useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement | null>(null);
  const projectInputRef = useRef<HTMLInputElement | null>(null);
  const threadInputRef = useRef<HTMLInputElement | null>(null);

  const documentsURL = process.env.REACT_APP_DOCUMENTS_API_URL;
  const threadsURL = process.env.REACT_APP_THREADS_API_URL;
  const projectsURL = process.env.REACT_APP_PROJECTS_API_URL;

  if (!documentsURL || !threadsURL || !projectsURL) {
    throw new Error('API URLs are not defined');
  }

  // State for Search Term
  const [searchTerm, setSearchTerm] = useState<string>('');

  // Access TechScoutingContext
  const techScoutingContext = useContext(TechScoutingContext);
  if (!techScoutingContext) {
    throw new Error('TechScoutingContext not found');
  }
  const { setTechResponse } = techScoutingContext;

  // Access StakeholderIdentificationContext
  const stakeholderContext = useContext(StakeholderIdentificationContext);
  if (!stakeholderContext) {
    throw new Error('StakeholderIdentificationContext not found');
  }
  const { setStakeholderResponse } = stakeholderContext;

  const fetchProjects = useCallback(async () => {
    try {
      const response = await axiosRequest(`${projectsURL}`, 'get');

      // Sort projects by creation date (most recent first)
      const sortedProjects = response.data.sort((a: Project, b: Project) => {
        return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
      });

      setProjects(sortedProjects);
    } catch (err: any) {
      if (err.response && err.response.status === 401) {
        setProjError('Unauthorized. Please log in with a valid email and password.');
      } else {
        setProjError('Error fetching projects');
      }
    }
  }, [projectsURL]);

  // Fetch Projects on Component Mount
  useEffect(() => {
    fetchProjects();
  }, [fetchProjects]);

  // Fetch Documents or Threads whenever searchTerm or activeTab changes
  useEffect(() => {
    if (activeTab === 'documents') {
      // Fetch Documents
      const fetchDocuments = async () => {
        try {
          const response = await axiosRequest(
            `${documentsURL}?search=${encodeURIComponent(searchTerm)}`,
            'get'
          );

          // Sort documents by creation date (most recent first)
          const sortedDocuments = response.data.sort((a: Document, b: Document) => {
            return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
          });

          setDocuments(sortedDocuments);
        } catch (err: any) {
          if (err.response && err.response.status === 401) {
            setDocError('Unauthorized. Please log in with a valid email and password.');
          } else {
            setDocError('Error fetching documents');
          }
        }
      };

      fetchDocuments();
    } else {
      // Fetch Threads
      const fetchThreads = async () => {
        try {
          const response = await axiosRequest(
            `${threadsURL}?search=${encodeURIComponent(searchTerm)}`,
            'get'
          );

          // Sort threads by creation date (most recent first)
          const sortedThreads = response.data.sort((a: Thread, b: Thread) => {
            return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
          });

          setThreads(sortedThreads);
        } catch (err: any) {
          if (err.response && err.response.status === 401) {
            setThreadError('Unauthorized. Please log in with a valid email and password.');
          } else {
            setThreadError('Error fetching threads');
          }
        }
      };

      fetchThreads();
    }
  }, [documentsURL, threadsURL, searchTerm, activeTab]);

  // Handlers for Documents
  const handleRenameDocument = async (documentId: string) => {
    if (!newDocumentTitle.trim()) {
      setDocError('Please enter a new document title.');
      return;
    }

    setDocError(null);
    setDocSuccessMessage(null);

    try {
      const response = await axiosRequest(
        `${documentsURL}${documentId}/rename/`,
        'patch',
        { documentTitle: newDocumentTitle }
      );

      // Update the document title in the documents list
      setDocuments(documents.map(doc =>
        doc.id === documentId ? { ...doc, documentTitle: response.data.documentTitle } : doc
      ));

      setDocSuccessMessage('Document renamed successfully');
      setEditingDocumentId(null); // Stop editing mode
      setNewDocumentTitle(''); // Clear the input field
    } catch (err: any) {
      if (err.response && err.response.status === 401) {
        setDocError('Unauthorized. Please log in with a valid email and password.');
      } else {
        setDocError('Error renaming document');
      }
    }
  };

  const handleEditDocumentClick = (documentId: string, currentTitle: string) => {
    setEditingDocumentId(documentId);
    setNewDocumentTitle(currentTitle); // Set the current title as the placeholder and initial value
    setTimeout(() => inputRef.current?.focus(), 0); // Focus the input after setting the state
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const handleUpload = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!file || !documentType.trim()) {
      setDocError('Please select a file and enter a document type.');
      return;
    }

    const formData = new FormData();
    formData.append('document', file);
    formData.append('documentType', documentType);

    setDocError(null);
    setDocSuccessMessage(null);

    try {
      const response = await axiosRequest(`${documentsURL}upload/`, 'post', formData);
      const newDocument: Document = {
        ...response.data,
        documentTitle: file.name,
        documentType: documentType,
        // Assuming other fields are included as per your Document type
      };
      setDocSuccessMessage('Document uploaded successfully');
      setDocuments([newDocument, ...documents]);
      setShowDocForm(false);
      setFile(null); // Reset the file input
      setDocumentType(''); // Reset the document type input
    } catch (err: any) {
      if (err.response && err.response.status === 401) {
        setDocError('Unauthorized. Please log in with a valid email and password.');
      } else {
        setDocError('Error uploading document');
      }
    }
  };

  const handleDeleteDocument = async (documentId: string) => {
    setDocError(null);
    setDocSuccessMessage(null);

    try {
      await axiosRequest(`${documentsURL}${documentId}/delete/`, 'delete');
      setDocSuccessMessage('Document deleted successfully');
      setDocuments(documents.filter(doc => doc.id !== documentId));
    } catch (err: any) {
      if (err.response && err.response.status === 401) {
        setDocError('Unauthorized. Please log in with a valid email and password.');
      } else {
        setDocError('Error deleting document');
      }
    }
  };

  const handleDownload = async (documentId: string, documentTitle: string) => {
    if (!documentId || !documentTitle) {
      setDocError('Invalid document data.');
      return;
    }

    setDocError(null);
    setDocSuccessMessage(null);

    try {
      const response = await axiosRequest(`${documentsURL}${documentId}/download/`, 'get');

      if (!response || !response.data || !response.data.url) {
        throw new Error('Download URL not found or document does not exist.');
      }

      const url = response.data.url;

      // Create an anchor element to trigger the download
      const link = document.createElement('a');
      link.href = url;
      link.download = documentTitle; // Use the original file name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

    } catch (err: any) {
      if (err.response && err.response.status === 401) {
        setDocError('Unauthorized. Please log in with a valid email and password.');
      } else {
        setDocError('Error downloading document');
      }
    }
  };

  // Handler to open Add to Project modal for documents
  const handleAddDocumentToProject = (documentId: string) => {
    setItemToAddId(documentId);
    setItemTypeToAdd('document');
    setShowAddToProjectModal(true);
    setAddToProjectError(null);
    setAddToProjectSuccessMessage(null);
  };

  // Handlers for Threads
  const handleRenameThread = async (threadId: string) => {
    if (!newThreadTitle.trim()) {
      setThreadError('Please enter a new thread title.');
      return;
    }

    setThreadError(null);
    setThreadSuccessMessage(null);

    try {
      const response = await axiosRequest(
        `${threadsURL}${threadId}/rename/`,
        'patch',
        { threadPrompt: newThreadTitle }
      );

      // Update the thread prompt in the threads list
      setThreads(threads.map(thread =>
        thread.id === threadId ? { ...thread, threadPrompt: response.data.threadPrompt } : thread
      ));

      setThreadSuccessMessage('Thread renamed successfully');
      setEditingThreadId(null); // Stop editing mode
      setNewThreadTitle(''); // Clear the input field
    } catch (err: any) {
      if (err.response && err.response.status === 401) {
        setThreadError('Unauthorized. Please log in with a valid email and password.');
      } else {
        setThreadError('Error renaming thread');
      }
    }
  };

  const handleEditThreadClick = (threadId: string, currentPrompt: string) => {
    setEditingThreadId(threadId);
    setNewThreadTitle(currentPrompt); // Set the current prompt as the placeholder and initial value
    setTimeout(() => threadInputRef.current?.focus(), 0); // Focus the input after setting the state
  };

  const handleDeleteThread = async (threadId: string) => {
    setThreadError(null);
    setThreadSuccessMessage(null);

    try {
      await axiosRequest(`${threadsURL}${threadId}/delete/`, 'delete');
      setThreadSuccessMessage('Thread deleted successfully');
      setThreads(threads.filter(thread => thread.id !== threadId));
    } catch (err: any) {
      if (err.response && err.response.status === 401) {
        setThreadError('Unauthorized. Please log in with a valid email and password.');
      } else {
        setThreadError('Error deleting thread');
      }
    }
  };

  // Handler to open Add to Project modal for threads
  const handleAddThreadToProject = (threadId: string) => {
    setItemToAddId(threadId);
    setItemTypeToAdd('thread');
    setShowAddToProjectModal(true);
    setAddToProjectError(null);
    setAddToProjectSuccessMessage(null);
  };

  // Handler to submit adding item to project using modalSelectedProjectId
  const submitAddItemToProject = async () => {
    if (!modalSelectedProjectId) {
      setAddToProjectError('Please select a project.');
      return;
    }

    try {
      if (itemTypeToAdd === 'document') {
        await axiosRequest(`${documentsURL}add-to-project/`, 'post', {
          document_id: itemToAddId,
          project_id: modalSelectedProjectId,
        });
        setAddToProjectSuccessMessage('Document added to project successfully.');
      } else if (itemTypeToAdd === 'thread') {
        await axiosRequest(`${threadsURL}add-to-project/`, 'post', {
          thread_id: itemToAddId,
          project_id: modalSelectedProjectId,
        });
        setAddToProjectSuccessMessage('Thread added to project successfully.');
      }
      setShowAddToProjectModal(false);
      // Reset modalSelectedProjectId after adding
      setModalSelectedProjectId('');
    } catch (err: any) {
      if (err.response && err.response.status === 401) {
        setAddToProjectError('Unauthorized. Please log in with a valid email and password.');
      } else {
        setAddToProjectError('Error adding item to project');
      }
    }
  };

  // Updated handleViewThread function
  const handleViewThread = (thread: Thread) => {
    console.log('thread.threadText:', thread.threadText);
    let responseData;
    try {
      // Parse the entire thread.threadText as a JSON object
      responseData = JSON.parse(thread.threadText);
    } catch (err) {
      console.error('Error parsing threadText:', err);
      setThreadError('Error parsing thread data.');
      return;
    }

    if (thread.threadType === 'TechScouting') {
      // Set the techResponse in context
      setTechResponse(responseData);
      setSection('TechScouting');
    } else if (thread.threadType === 'StakeholderIdentification') {
      // Set the stakeholderResponse in context
      setStakeholderResponse(responseData);
      setSection('StakeholderIdentification');
    } else {
      setThreadError('Unsupported thread type.');
    }
  };

  // Handlers for Projects
  const handleCreateProject = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!newProjectName.trim()) {
      setProjError('Please enter a project name.');
      return;
    }

    setProjError(null);
    setProjSuccessMessage(null);

    try {
      const response = await axiosRequest(`${projectsURL}create/`, 'post', {
        name: newProjectName,
        description: newProjectDescription,
      });

      const newProject: Project = {
        ...response.data,
        name: response.data.name,
        description: response.data.description,
        is_default: response.data.is_default, // Ensure `is_default` is captured
      };

      setProjects([newProject, ...projects]);
      setProjSuccessMessage('Project created successfully');
      setShowProjForm(false);
      setNewProjectName('');
      setNewProjectDescription('');
    } catch (err: any) {
      if (err.response && err.response.status === 401) {
        setProjError('Unauthorized. Please log in with a valid email and password.');
      } else {
        setProjError('Error creating project');
      }
    }
  };

  const handleRenameProject = async (projectId: string) => {
    if (!newProjectTitle.trim()) {
      setProjError('Please enter a new project name.');
      return;
    }

    setProjError(null);
    setProjSuccessMessage(null);

    try {
      const response = await axiosRequest(
        `${projectsURL}${projectId}/update/`,
        'patch',
        { name: newProjectTitle }
      );

      // Update the project name in the projects list using the updated project data
      setProjects(projects.map(proj =>
        proj.id === projectId ? { ...proj, name: response.data.project.name } : proj
      ));

      setProjSuccessMessage(response.data.message); // Use the message from the backend
      setEditingProjectId(null); // Stop editing mode
      setNewProjectTitle(''); // Clear the input field
    } catch (err: any) {
      if (err.response && err.response.status === 401) {
        setProjError('Unauthorized. Please log in with a valid email and password.');
      } else {
        setProjError('Error renaming project');
      }
    }
  };

  const handleEditProjectClick = (projectId: string, currentName: string) => {
    setEditingProjectId(projectId);
    setNewProjectTitle(currentName); // Set the current name as the placeholder and initial value
    setTimeout(() => projectInputRef.current?.focus(), 0); // Focus the input after setting the state
  };

  const handleDeleteProject = async (projectId: string) => {
    setProjError(null);
    setProjSuccessMessage(null);

    try {
      const projectToDelete = projects.find(proj => proj.id === projectId);
      if (projectToDelete?.is_default) {
        setProjError('Default Project cannot be deleted.');
        return;
      }

      await axiosRequest(`${projectsURL}${projectId}/delete/`, 'delete');
      setProjSuccessMessage('Project deleted successfully');
      setProjects(projects.filter(proj => proj.id !== projectId));
    } catch (err: any) {
      if (err.response && err.response.status === 401) {
        setProjError('Unauthorized. Please log in with a valid email and password.');
      } else {
        setProjError('Error deleting project');
      }
    }
  };

  const handleViewProject = (projectId: string) => {
    setSelectedLibraryProjectId(projectId);
  };

  const formatDateTime = (dateString: string) => {
    const date = new Date(dateString);

    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    };

    const formattedDate = date.toLocaleDateString(undefined, options);
    const formattedTime = date.toLocaleTimeString(undefined, { hour12: false });

    return `${formattedDate} ${formattedTime}`;
  };

  // Conditional Rendering: Show ProjectDetail when selectedProjectId is set
  if (selectedLibraryProjectId) {
    return (
      <ProjectDetail
        projectId={selectedLibraryProjectId}
        isDarkMode={isDarkMode}
        setSection={setSection}
        setSelectedProjectId={setSelectedLibraryProjectId} // Pass this prop
      />
    );
  }

  return (
    <>
      <Helmet>
        <title>Library</title>
      </Helmet>
      <nav aria-label="Primary Navigation" className="primary-nav">
        {/* Navigation items go here */}
      </nav>
      <div className={`library-page ${isDarkMode ? 'dark' : 'light'}`}>
        <div className="library-header">
          <h1 className="library-title">Library</h1>
          <input
            type="text"
            className="library-search-input"
            placeholder={`Search ${activeTab}...`}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            aria-label={`Search ${activeTab}`}
            autoComplete='on'
          />
          <div className="library-toggle-container">
            <button
              className={`library-toggle-button ${activeTab === 'threads' ? 'active' : ''}`}
              onClick={() => setActiveTab('threads')}
              aria-pressed={activeTab === 'threads'}
            >
              Threads
            </button>
            <span className="library-toggle-separator">/</span>
            <button
              className={`library-toggle-button ${activeTab === 'documents' ? 'active' : ''}`}
              onClick={() => setActiveTab('documents')}
              aria-pressed={activeTab === 'documents'}
            >
              Documents
            </button>
          </div>
        </div>
        <div className="library-underline"></div>
        <main
          role="main"
          tabIndex={-1}
          className={`library-main-content ${isDarkMode ? 'dark' : 'light'}`}
        >
          <div className="library-tables-container">
            {/* Documents or Threads Section */}
            <div className="library-container documents-container">
              {activeTab === 'documents' ? (
                <>
                  {/* Documents Section */}
                  <div className="library-header-container">
                    <div className="library-header-title-container">
                      <h2 tabIndex={0} role="heading" aria-level={2}>Documents</h2>
                      {!isInProposalsGroup && (
                        <button
                          onClick={() => setSection('ATODocuments')}
                          className="library-ato-documents-button"
                          aria-label="View ATO Documents"
                        >
                          ATO
                          <span className="library-tooltip-text">View ATO Documentation</span>
                        </button>
                      )}
                      <button
                        onClick={() => setShowDocForm(true)}
                        className="library-upload-button"
                        aria-label="Upload file"
                      >
                        +
                        <span className="library-tooltip-text">Upload a document from your computer</span>
                      </button>
                    </div>
                    <p tabIndex={0} role="note">List of saved user documents.</p>
                  </div>
                  {docError && <p className="library-error-message" role="alert">{docError}</p>}
                  {docSuccessMessage && <p className="library-success-message" role="alert">{docSuccessMessage}</p>}

                  {/* Documents Upload Modal */}
                  <LibraryModal
                    isOpen={showDocForm}
                    onClose={() => setShowDocForm(false)}
                    title="Upload Document"
                  >
                    <form className="library-upload-form" onSubmit={handleUpload}>
                      <label htmlFor="file">Select a file:</label>
                      <input type="file" id="file" onChange={handleFileChange} required />
                      <label htmlFor="fileType">Document Type:</label>
                      <input
                        type="text"
                        id="fileType"
                        value={documentType}
                        onChange={(e) => setDocumentType(e.target.value)}
                        required
                        placeholder="Enter document type"
                      />
                      <button type="submit">
                        Upload
                      </button>
                    </form>
                  </LibraryModal>

                  {/* Add to Project Modal */}
                  <LibraryModal
                    isOpen={showAddToProjectModal}
                    onClose={() => setShowAddToProjectModal(false)}
                    title="Add to Project"
                  >
                    <div className="library-add-to-project-form">
                      <label htmlFor="projectSelect">Select a project:</label>
                      <select
                        id="projectSelect"
                        value={modalSelectedProjectId}
                        onChange={(e) => setModalSelectedProjectId(e.target.value)}
                      >
                        <option value="">-- Select Project --</option>
                        {projects.map((project) => (
                          <option key={project.id} value={project.id}>
                            {project.name}
                          </option>
                        ))}
                      </select>
                      {addToProjectError && (
                        <p className="library-error-message" role="alert">
                          {addToProjectError}
                        </p>
                      )}
                      {addToProjectSuccessMessage && (
                        <p className="library-success-message" role="alert">
                          {addToProjectSuccessMessage}
                        </p>
                      )}
                      <button
                        type="button"
                        onClick={submitAddItemToProject}
                        className="library-add-button"
                      >
                        Add to Project
                      </button>
                    </div>
                  </LibraryModal>

                  {/* Documents Table */}
                  <table className="library-table" role="table" aria-label="Documents table">
                    <thead>
                      <tr role="row">
                        <th tabIndex={0} role="columnheader">Name</th>
                        <th tabIndex={0} role="columnheader">Type</th>
                        <th tabIndex={0} role="columnheader">Created At</th>
                        <th tabIndex={0} role="columnheader">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {documents.length === 0 ? (
                        <tr>
                          <td className="library-no-documents-message" colSpan={4}>No documents found.</td>
                        </tr>
                      ) : (
                        documents.map((document) => (
                          <tr key={document.id} role="row">
                            <td data-label="Name" tabIndex={0} role="cell" title={document.documentTitle}>
                              <div className="library-name-cell">
                                {editingDocumentId === document.id ? (
                                  <div className="library-rename-container">
                                    <input
                                      ref={inputRef}
                                      type="text"
                                      value={newDocumentTitle}
                                      onChange={(e) => setNewDocumentTitle(e.target.value)}
                                      placeholder={newDocumentTitle}
                                      onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                          handleRenameDocument(document.id);
                                        }
                                      }}
                                      aria-label={`Rename document ${document.documentTitle}`}
                                    />
                                    <button
                                      onClick={() => handleRenameDocument(document.id)}
                                      aria-label={`Save new title for ${document.documentTitle}`}
                                      className="library-save-button"
                                    >
                                      Save
                                    </button>
                                  </div>
                                ) : (
                                  <>
                                    <span className="library-document-title">{document.documentTitle}</span>
                                    <span
                                      className="library-rename-icon-container"
                                      onClick={() => handleEditDocumentClick(document.id, document.documentTitle)}
                                      tabIndex={0}
                                      role="button"
                                      aria-label={`Rename document ${document.documentTitle}`}
                                      onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                          handleEditDocumentClick(document.id, document.documentTitle);
                                        }
                                      }}
                                    >
                                      <FontAwesomeIcon icon={faPencil} className="library-rename-icon" />
                                      <span className="library-rename-tooltip">Rename document</span>
                                    </span>
                                  </>
                                )}
                              </div>
                            </td>
                            <td data-label="Type" tabIndex={0} role="cell">{document.documentType}</td>
                            <td data-label="Created At" tabIndex={0} role="cell">{formatDateTime(document.created_at)}</td>
                            <td data-label="Actions" role="cell">
                              <button
                                aria-label={`Download document ${document.documentTitle}`}
                                onClick={() => handleDownload(document.id, document.documentTitle)}
                                className="library-text-button"
                                tabIndex={0}
                              >
                                Download
                              </button>
                              <button
                                onClick={() => handleDeleteDocument(document.id)}
                                aria-label={`Delete ${document.documentTitle}`}
                                className="library-text-button"
                                tabIndex={0}
                              >
                                Delete
                              </button>
                              {/* Added Project button */}
                              <button
                                onClick={() => handleAddDocumentToProject(document.id)}
                                aria-label={`Add document ${document.documentTitle} to project`}
                                className="library-text-button"
                                tabIndex={0}
                              >
                                Project
                              </button>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </>
              ) : (
                <>
                  {/* Threads Section */}
                  <div className="library-header-container">
                    <div className="library-header-title-container">
                      <h2 tabIndex={0} role="heading" aria-level={2}>Threads</h2>
                    </div>
                    <p tabIndex={0} role="note">List of saved user threads.</p>
                  </div>
                  {threadError && <p className="library-error-message" role="alert">{threadError}</p>}
                  {threadSuccessMessage && <p className="library-success-message" role="alert">{threadSuccessMessage}</p>}

                  {/* Add to Project Modal */}
                  <LibraryModal
                    isOpen={showAddToProjectModal}
                    onClose={() => setShowAddToProjectModal(false)}
                    title="Add to Project"
                  >
                    <div className="library-add-to-project-form">
                      <label htmlFor="projectSelect">Select a project:</label>
                      <select
                        id="projectSelect"
                        value={modalSelectedProjectId}
                        onChange={(e) => setModalSelectedProjectId(e.target.value)}
                      >
                        <option value="">-- Select Project --</option>
                        {projects.map((project) => (
                          <option key={project.id} value={project.id}>
                            {project.name}
                          </option>
                        ))}
                      </select>
                      {addToProjectError && (
                        <p className="library-error-message" role="alert">
                          {addToProjectError}
                        </p>
                      )}
                      {addToProjectSuccessMessage && (
                        <p className="library-success-message" role="alert">
                          {addToProjectSuccessMessage}
                        </p>
                      )}
                      <button
                        type="button"
                        onClick={submitAddItemToProject}
                        className="library-add-button"
                      >
                        Add to Project
                      </button>
                    </div>
                  </LibraryModal>

                  {/* Threads Table */}
                  <table className="library-table" role="table" aria-label="Threads table">
                    <thead>
                      <tr role="row">
                        <th tabIndex={0} role="columnheader">Prompt</th>
                        <th tabIndex={0} role="columnheader">Type</th>
                        <th tabIndex={0} role="columnheader">Created At</th>
                        <th tabIndex={0} role="columnheader">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {threads.length === 0 ? (
                        <tr>
                          <td className="library-no-documents-message" colSpan={4}>No threads found.</td>
                        </tr>
                      ) : (
                        threads.map((thread) => (
                          <tr key={thread.id} role="row">
                            <td data-label="Prompt" tabIndex={0} role="cell" title={thread.threadPrompt}>
                              <div className="library-name-cell">
                                {editingThreadId === thread.id ? (
                                  <div className="library-rename-container">
                                    <input
                                      ref={threadInputRef}
                                      type="text"
                                      value={newThreadTitle}
                                      onChange={(e) => setNewThreadTitle(e.target.value)}
                                      placeholder={newThreadTitle}
                                      onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                          handleRenameThread(thread.id);
                                        }
                                      }}
                                      aria-label={`Rename thread ${thread.threadPrompt}`}
                                    />
                                    <button
                                      onClick={() => handleRenameThread(thread.id)}
                                      aria-label={`Save new prompt for ${thread.threadPrompt}`}
                                      className="library-save-button"
                                    >
                                      Save
                                    </button>
                                  </div>
                                ) : (
                                  <>
                                    <span className="library-document-title">{thread.threadPrompt}</span>
                                    <span
                                      className="library-rename-icon-container"
                                      onClick={() => handleEditThreadClick(thread.id, thread.threadPrompt)}
                                      tabIndex={0}
                                      role="button"
                                      aria-label={`Rename thread ${thread.threadPrompt}`}
                                      onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                          handleEditThreadClick(thread.id, thread.threadPrompt);
                                        }
                                      }}
                                    >
                                      <FontAwesomeIcon icon={faPencil} className="library-rename-icon" />
                                      <span className="library-rename-tooltip">Rename thread</span>
                                    </span>
                                  </>
                                )}
                              </div>
                            </td>
                            <td data-label="Type" tabIndex={0} role="cell">{thread.threadType}</td>
                            <td data-label="Created At" tabIndex={0} role="cell">{formatDateTime(thread.created_at)}</td>
                            <td data-label="Actions" role="cell">
                              <button
                                aria-label={`View thread ${thread.threadPrompt}`}
                                onClick={() => handleViewThread(thread)}
                                className="library-text-button"
                                tabIndex={0}
                              >
                                View
                              </button>
                              <button
                                onClick={() => handleDeleteThread(thread.id)}
                                aria-label={`Delete ${thread.threadPrompt}`}
                                className="library-text-button"
                                tabIndex={0}
                              >
                                Delete
                              </button>
                              {/* Added Project button */}
                              <button
                                onClick={() => handleAddThreadToProject(thread.id)}
                                aria-label={`Add thread ${thread.threadPrompt} to project`}
                                className="library-text-button"
                                tabIndex={0}
                              >
                                Project
                              </button>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </>
              )}
            </div>

            {/* Projects Section */}
            <div className="library-container projects-container">
              <div className="library-header-container">
                <div className="library-header-title-container">
                  <h2 tabIndex={0} role="heading" aria-level={2}>
                    Projects
                  </h2>
                  <button
                    onClick={() => setShowProjForm(true)}
                    className="library-upload-button"
                    aria-label="Create new project"
                  >
                    +
                    <span className="library-tooltip-text">Create a new project</span>
                  </button>
                </div>
                <p tabIndex={0} role="note">
                  List of your projects.
                </p>
              </div>
              {projError && (
                <p className="library-error-message" role="alert">
                  {projError}
                </p>
              )}
              {projSuccessMessage && (
                <p className="library-success-message" role="alert">
                  {projSuccessMessage}
                </p>
              )}

              {/* Projects Creation Modal */}
              <LibraryModal
                isOpen={showProjForm}
                onClose={() => setShowProjForm(false)}
                title="Create Project"
              >
                <form className="library-upload-form" onSubmit={handleCreateProject}>
                  <label htmlFor="projectName">Project Name:</label>
                  <input
                    type="text"
                    id="projectName"
                    value={newProjectName}
                    onChange={(e) => setNewProjectName(e.target.value)}
                    required
                    placeholder="Enter project name"
                  />
                  <label htmlFor="projectDescription">Description:</label>
                  <textarea
                    id="projectDescription"
                    value={newProjectDescription}
                    onChange={(e) => setNewProjectDescription(e.target.value)}
                    placeholder="Enter project description"
                  />
                  <button type="submit">Create Project</button>
                </form>
              </LibraryModal>

              {/* Projects Table */}
              <table className="library-table" role="table" aria-label="Projects table">
                <thead>
                  <tr role="row">
                    <th tabIndex={0} role="columnheader">
                      Name
                    </th>
                    <th tabIndex={0} role="columnheader">
                      Created At
                    </th>
                    <th tabIndex={0} role="columnheader">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {projects.length === 0 ? (
                    <tr>
                      <td className="library-no-documents-message" colSpan={3}>
                        No projects found.
                      </td>
                    </tr>
                  ) : (
                    projects.map((project) => (
                      <tr key={project.id} role="row">
                        <td
                          data-label="Name"
                          tabIndex={0}
                          role="cell"
                          title={project.name}
                        >
                          <div className="library-name-cell">
                            {editingProjectId === project.id ? (
                              <div className="library-rename-container">
                                <input
                                  ref={projectInputRef}
                                  type="text"
                                  value={newProjectTitle}
                                  onChange={(e) => setNewProjectTitle(e.target.value)}
                                  placeholder={newProjectTitle}
                                  onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                      handleRenameProject(project.id);
                                    }
                                  }}
                                  aria-label={`Rename project ${project.name}`}
                                />
                                <button
                                  onClick={() => handleRenameProject(project.id)}
                                  aria-label={`Save new name for ${project.name}`}
                                  className="library-save-button"
                                >
                                  Save
                                </button>
                              </div>
                            ) : (
                              <>
                                <span className="library-project-title">
                                  {project.name}
                                </span>
                                <span
                                  className="library-rename-icon-container"
                                  onClick={() => handleEditProjectClick(project.id, project.name)}
                                  tabIndex={0}
                                  role="button"
                                  aria-label={`Rename project ${project.name}`}
                                  onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                      handleEditProjectClick(project.id, project.name);
                                    }
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={faPencil}
                                    className="library-rename-icon"
                                  />
                                  <span className="library-rename-tooltip">Rename project</span>
                                </span>
                              </>
                            )}
                          </div>
                        </td>
                        <td data-label="Created At" tabIndex={0} role="cell">
                          {formatDateTime(project.created_at)}
                        </td>
                        <td data-label="Actions" role="cell">
                          <button
                            aria-label={`View project ${project.name}`}
                            onClick={() => handleViewProject(project.id)}
                            className="library-text-button"
                            tabIndex={0}
                          >
                            View
                          </button>
                          <button
                            aria-label={`Delete project ${project.name}`}
                            onClick={() => handleDeleteProject(project.id)}
                            className="library-text-button"
                            tabIndex={0}
                            disabled={project.is_default} // Disable Delete Button for Default Project
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default Library;
